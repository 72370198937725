.slick-dots {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  button {
    height: 12px;
    width: 12px;
    margin: 4px;
    padding: 0;
    background: $medium-gray;
    border-radius: 6px;
    text-indent: -9999em;
  }

  .slick-active {
    button {
      background: $red;
    }
  }
}
