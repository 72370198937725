.paragraphs-item-single-testimonial {
  margin: 0px auto;
  .content {
    padding: 24px 25px 50px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 36px;
    @include breakpoint($screen-m) {
      padding: 24px 64px 112px 64px;
      flex-direction: row;
    }
    &:before,
    &:after {
      display: block;
      margin: 0;
      text-align: center;
      @include breakpoint($screen-m) {
        text-align: left;
      }
    }
    &:before {
      content: url('../images/quote-left.svg');
      align-self: flex-start;
    }
    &:after {
      content: url('../images/quote-right.svg');
      align-self: flex-end;
    }
    p {
      line-height: 1.6;
      margin: 0 60px;
      text-align: center;
    }
  }
  .group-attribution-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .field-name-field-quotation,
  .field-name-field-attribution {
    max-width: 745px;
    color: $black;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.45px;
    @include breakpoint($screen-m) {
      font-size: 24px;
    }
  }
  .field-name-field-attribution {
    > .field-items {
      margin-top: 10px;
    }
    &:before {
      content: '\2014';
    }
  }
  &.dark-background {
    background: $white-smoke;
  }
}
