#messages {
  max-width: 100%;
  overflow: auto;
  margin-left: 40px;
  margin-right: 40px;
}
.messages--status,
.messages--error {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 10px;
  color: $green;
  font-weight: bold;
}
.messages--error {
  color: $red;
}

