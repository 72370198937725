.menu-block-toggle {
  font-size: rem(15);
  line-height: rem(20);
  padding: 15px 9px 14px 9px;
  font-weight: normal;
  display: block;
  color: $white;
  text-align: right;
  .font-ivory-headline {
    font-size: rem(18);
  }

  .fa {
    font-size: rem(12);
  }

  @include breakpoint($screen-s) {
    font-size: rem(18);

    .font-ivory-headline {
      font-size: rem(22);
    }

    .fa {
      font-size: rem(14);
    }
  }
}

.block-paradigm-blocks-plays-menu-icons {
  max-width: 550px;
  text-align: center;
  color: $red;
  img {
    width: 85px;
  }
}
