.tooltipster-base {
  z-index: 998;
  max-height: calc(90% - 150px);
  overflow-x: hidden;
  overflow-y: auto;
}
.group-tooltip-content {
  .field-name-field-image {
    float: right;
  }
  .field-name-field-popup-heading {
    font-size: 18px;
    margin-bottom: .5em;
  }
  .field-name-field-popup-body ol {
    margin: 0;
  }
  .stage-direction {
    padding-left: 20px;
  }
}
.tooltipster-content {
  .line-placeholder {
    line-height: $base-line-height / $base-font-size * 1.25em;
  }
  h3.character-name {
    padding-left: 0;
    padding-right: 0;
  }
  p, ul, ol, table {
    margin-top: .5em;
    margin-bottom: .5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul {
    list-style-type: disc;
    padding-left: 17px;
  }
  ol {
    list-style-type: decimal;
    padding-left: 17px;
  }
}
.tooltipster-shadow {
  .tooltipster-content {
    font-family: $baskerville;
    line-height: 20px;
  }
}
