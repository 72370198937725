.block-paradigm-blocks-logo-block {
  display: none;

  @include breakpoint($screen-l) {
    display: block;
  }

  .content {
    @include clearfix;
  }
  .block-paradigm-blocks__logo-link {
    display: block;
    @include clearfix;
    img {
      display: block;
    }
  }
}
.region-sticky-nav {
  .block-paradigm-blocks-logo-block {
    position: fixed;
    left: 30px;
    opacity: 1;
    top: 0;
    transition: all .8s ease-in-out;
    &.is-hiding {
      transform: translateY(-100px);
      opacity: 0;
    }
  }
}
.admin-menu {
  .region-sticky-nav {
    .block-paradigm-blocks-logo-block {
      top: 30px;
    }
  }
}
.region-footer {
  .block-paradigm-blocks-logo-block {
    float: right;
  }
}
