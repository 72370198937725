// Typography
$baskerville: 'Libre Baskerville', Times, 'Times New Roman', Georgia, 'DejaVu Serif', serif;
$impact: Impact, 'Arial Narrow', Helvetica, sans-serif;
$ivory-headline: 'Ivory W00', 'Libre Baskerville', Times, 'Times New Roman', Georgia, 'DejaVu Serif', serif;
$droid-serif: 'Droid Serif', serif;
$base-font-family: $baskerville;
$heading-font-family: $baskerville;
$playfair-display: 'Playfair Display', serif;
$font-awesome: 'FontAwesome';
$icon-font: $font-awesome;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 2.125;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 4px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$blue: #477dca;
$blue-light: #94c0ff;
$black: #000;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #f0f0f0;

$white: #ffffff;
$grey-even-more-lightest: #f8f8f8;
$grey-lightest: #f0f0f0;
$grey-lighter: #ddd;
$grey-light: #b4b4b4;
$grey-medium-lighter: #C6C6C6;
$grey-medium: #959492;
$grey-medium-darker: #929292;
$grey-dark: #7a7a7a;
$grey-neutral: #2c2c2c;
$grey-darker: #020202;
$grey-accessible: #757575;
$grey-table: #fafafa;
$white-smoke: #f7f7f7;
$red: #a82c29;
$dark-red: #6B1C1A;
$darker-red: #3b1216;
$red-light: #FEF3F3;
$yellow: #ffff1b;
$green: #2a9442;
$green-medium: #78ff82;
$green-light: #F8FCF9;
$pink: #feaeff;
$light-coral: #ffa5a5;
$crimson-red: #a72c29;

$yellow-highlight: rgba(255, 255, 27, 0.5);
$yellow-blue-highlight: rgba(83, 151, 199, 0.5);
$yellow-green-highlight: rgba(163, 224, 82, 0.5);
$yellow-pink-highlight: rgba(201, 133, 204, 0.5);
$blue-highlight: rgba(148, 192, 255, 0.5);
$blue-green-highlight: rgba(105, 183, 150, 0.5);
$blue-pink-highlight: rgba(180, 152, 236, 0.5);
$green-highlight: rgba(120, 255, 130, 0.5);
$green-pink-highlight: rgba(88, 179, 98, 0.5);
$pink-highlight: rgba(254, 174, 255, 0.5);
$grey-highlight: rgba(50,50,50, 0.1);

$artool-text-color: $grey-neutral;

// Mulitple choice quiz
$option-border-color: #8F8F8F;

// Font Colors
$base-background-color: #fff;
$base-font-color: $black;
$action-color: $red;

// Border
$base-border-color: $grey-lighter;
$base-border: 1px solid $base-border-color;

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Screen sizes
$screen-xxl: 1529px;
$screen-xl: 1279px;
$screen-l: 1023px;
$screen-m: 767px;
$screen-s: 479px;
