.node-type-full-scene-audio {

  .node-full-scene-audio {
    display: flex;
    flex-direction: column;
    align-items: center;

    .field-name-field-index-introduction {
      background-image: url(../images/icon-audio-default.png);
    }

    .group-act-and-scene {
      &::after {
        content: "Audio";
        margin-left: 8px;
      }
    }

    .field-name-field-audio-file {
      width: 80%;
    }

    .field-name-field-audio-file {
      padding-bottom: 65px;
      .sm2-bar-ui {
        border-radius: 10px;
      }
    }

    .lines-renderer-wrapper,
    .field-label,
    .field-name-field-index-reference-notes {
      width: 100%
    }
  }
}
