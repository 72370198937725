.admin-menu {
  .block-paradigm-blocks-scene-summary-content .node-what-s-happening,
  .block-paradigm-blocks-video-digest {
    top: 133px;
  }
}

@mixin video-digest-view {
  .view-content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .video-digest-thumbnail-wrapper {
    width: 100%;
    padding: 5%;

    @include breakpoint($screen-m) {
      width: 50%;
      padding: 2%;
    }
    @include breakpoint($screen-l) {
      width: 25%;
      padding: 1%;
    }

    .paragraphs-item-video-popup,
    .paragraphs-item-video-link {
      margin: 0;

      > .content {
        padding: 0;
      }
    }
  }

  .video-digest-video-background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $black;
  }

  .video-digest-link-text-layer {
    @extend %h3;
    font-weight: 700;
    color: $white;
    text-align: center;
    background-color: rgba(168, 44, 41, 0.88);
    padding: 3em 0;

    &:hover {
      opacity: 0;
    }

    img.play-icon {
      max-width: 22%;
      vertical-align: middle;
    }
  }

  .video-digest-link-thumbnail-description {
    background-color: $white;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    min-height: 72px;

    img.icon {
      width: 16%;
      margin: 10px 0 10px 20px;
    }
  }

  .video-digest-link-thumbnail-description-infoplate-text {
    margin-left: 20px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
  }

  .video-digest-link-thumbnail-description-infoplate-text-title {
    color: $black;
  }
  .video-digest-link-thumbnail-description-infoplate-text-subtitle {
    color: $grey-dark;
  }

  .video-digest-close {
    display: none;
    position: absolute;
    right: 0;
    margin-right: 20px;
    margin-top: -40px;
    color: $red;
    text-align: center;
    background-color: white;
    border-radius: 9px;
    border: 1px solid rgba(125, 125, 125, 0.360784);
    padding: 0px 20px;

    @include breakpoint($screen-s) {
      display: block;
    }
    .fa {
      margin-right: 5px;
    }
    &:hover {
      cursor: pointer;
      background-color: $red;
      color: $white;
    }
  }
}

.block-paradigm-blocks-scene-summary-content .node-what-s-happening,
.block-paradigm-blocks-video-digest {
  box-shadow: inset 0px 0px 100px -13px rgba(0,0,0,0.75);
  overflow: scroll;
  position: fixed;
  top: 103px;
  left: 0;
  right: 0;
  z-index: 101;
  height: 0;
  background-color: $grey-lightest;

  // Prevent fancybox popups from changing the right margin for this element.
  &.fancybox-margin {
    margin-right: -9999rem;
  }

  > .content {
    position: relative;
    overflow-y: auto;
  }

  @include video-digest-view;
}

.view-id-folio_page_video_digest_from_paragraphs_ {
  .views-exposed-widgets {
    display: flex;
    justify-content: center;

    .chosen-container {
      min-width: 200px !important;
      border-radius: 3px;
      background-image: url(../images/chevron-down.png);
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: 95% 18px;
      background-color: $white;
      border-radius: 3px;
    }

    .chosen-choices {
      border: none;
      padding: 10px 9% 10px 10px;
      background-image: none;
      background-color: transparent;
      border-radius: 3px;
    }

    .search-choice {
      border: 1px solid $red;
      background-image: none;
      margin: 2px 5px 2px 0;
      padding: 1px 18px 1px 3px;
      font-size: 12px;
      span {
        font-family: $baskerville;
        font-size: 1.2em;
        line-height: 1.2em;
        color: $red;
      }
      .search-choice-close {
        top: 6px;
        background: url(../images/chosen-close.png) no-repeat !important;
        &:hover {
          background: url(../images/chosen-close-dark.png) no-repeat !important;
        }
      }
    }

    .chosen-drop {
      li {
        font-family: $baskerville;
        font-size: 1.2em;
        line-height: 2.5em;
      }
      .highlighted {
        background-color: $grey-lightest;
        background-image: none;
        color: $black;
      }
    }
  }
}

.video-digest-close-area {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 50;
  display: none;
}
