.node-popup-note-index-item {
  width: 90%;
  margin: 30px auto 0;

  &.view-mode-search-result-teaser {
     margin-top: 0;
  }

  .field-name-field-index-introduction {
    background-image: url(../images/icon-word-nerd.png);
    font-style: italic;
    line-height:$base-line-height;
    color: $dark-gray;

    @media all and (max-width: $screen-l) {
      background-position: top 10px left;
      &:before {
        content: '';
        display: block;
        float: left;
        padding: 33px 40px;
      }
    }
  }
  .field-name-field-image {
    width: 50%;
    margin: 30px auto 50px;

    @media all and (max-width: $screen-l) {
      width: 75%;
    }

    @media all and (max-width: $screen-m) {
      width: 100%;
    }
    img {
      margin: 0 auto;
      height: inherit;
      width: auto;
      max-height: 400px;
      display: block;
    }
  }
  .group-two-columns-popup-index {
    display: flex;
    margin-bottom: 75px;
    justify-content: space-between;

    @media all and (max-width: $screen-m) {
      flex-direction: column;
    }
    .lines-renderer-wrapper {
      flex-basis: 48%;
      .line-number{
        display: none;
      }
      &:before {
        content: 'Shakespearean Text';
        line-height: 1em;
        font-size: 1.3em;
        color: $dark-gray;
        display: block;
        margin-bottom: 20px;
        margin-top: 10px;
        font-weight: bold;
      }

      @media all and (max-width: $screen-m) {
        margin-bottom: 40px;
      }
    }
    .field-name-field-popup-body {
      flex-basis: 48%;
      ul, ol {
        list-style-type: inherit;
      }
      &:before {
        @extend .lines-renderer-wrapper:before;
        content: "Analysis";
      }
    }
  }
  .line {
    padding-right: 30px;
  }
  .stage-direction {
    padding: 0px 55px;
  }
}
