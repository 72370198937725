.block-paradigm-blog-blog-digest-cta {
  margin-top: 70px;
  margin-bottom: 70px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;

  .blog-digest-cta-text {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
    line-height: 35px;
  }

  #mc_embed_signup_scroll {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .mc-field-group:not(:last-child) {
    margin-right: 10px;
  }

  input[type=text],
  input[type=email] {
    margin-bottom: 10px;
    padding: 15px 10px;
    background-color: $light-gray;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    color: $grey-neutral;
    font-size: 16px;
    line-height: 22px;
  }

  input[type=submit] {
    padding: 15px 30px;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.035px;
  }
}