.block-menu-menu-footer {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
  text-align: center;
  line-height: rem(30px);

  &.contextual-links-region {
    @include breakpoint($screen-m) {
      position: static;
    }

    @include breakpoint($screen-xl) {
      position: relative;
    }
  }

  @include breakpoint($screen-xl) {
    margin-bottom: 0;
  }

  .menu {
    display: flex;
    flex-wrap: wrap;

    @include breakpoint($screen-m) {
      top: 25px;
      right: 18px;
    }

    @include breakpoint($screen-xl) {
      position: static;
      margin-top: 0;
    }

    > li {
      margin-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px;
      display: inline;
      flex: 1 0 50%;

      @include breakpoint($screen-xl) {
        margin-bottom: 0;
        padding-left: 5px;
        padding-right: 5px;
        flex: 0 1 auto;
      }

      &:nth-child(odd) {
        text-align: left;
      }

      &:nth-child(even) {
        text-align: right;
      }

      &.leaf {
        @include breakpoint($screen-xl) {
          text-align: right;
          font-size: rem(18);
          line-height: rem(40);
          margin: 0px 15px;
        }

        @include breakpoint($screen-xl) {
          text-align: center;
          font-size: rem(16);
          line-height: rem(30);
        }
      }
    }

    a {
      color: $black;
      font-size: 16px;
      line-height: 150%;
      font-weight: bold;
    }
  }
}
