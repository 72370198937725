.view-blog {
  &:not(.view-display-id-attachment_1) {
    padding: 0px 15px;
    @include breakpoint($screen-xl) {
      padding: 0px;
    }
  }

  .view-filters {
    .views-exposed-widgets {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      margin-bottom: 35px;
    }
    .views-exposed-widget {
      float: none;
      padding: 0 30px 0 0;
      &:last-child {
        padding-right: 0;
      }
    }
    .views-widget-filter-title,
    .views-widget-filter-field_blog_category_tid {
      flex: 1;
      min-width: 200px;
    }

    label {
      margin-bottom: 0px;
      color: $grey-neutral;
      font-weight: 400;
      line-height: 30px;
    }
    input[type=text],
    select {
      margin-bottom: 0px;
      padding: 15px 10px;
      background-color: $light-gray;
      background-position: calc(100% - 10px);
      background-repeat: no-repeat;
      border: none;
      border-radius: 4px;
      color: $grey-neutral;
      font-size: 16px;
      line-height: 22px;
    }
    input[type=text] {
      padding-right: 38px;
      background-image: url('../images/spyglass.svg');
      box-shadow: none;
    }
    select {
      appearance: none;
      width: 100%;
      padding-right: 28px;
      background-image: url('../images/arrow-down.svg');
    }
    .form-submit {
      margin-top: 30px;
      padding: 15px 30px;
      border-radius: 4px;
      font-size: 14px;
      line-height: 22px;
    }
  }

  .view-content {
    display: flex;
    flex-wrap: wrap;
  }

  .pager {
    margin: 35px 0px 50px;
  }

  .views-row {
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 2px solid $light-gray;

    @include breakpoint($screen-s) {
      &:nth-child(2n+1) {
        width: calc(((100% - 30px) / 2) + 15px);
        article {
          padding-right: 15px;
          border-right: 1px solid $light-gray;
        }
      }
      &:nth-child(2n+2) {
        width: calc(((100% - 30px) / 2) + 15px);
        article {
          padding-left: 15px;
          border-left: 1px solid $light-gray;
        }
      }
    }

    @include breakpoint($screen-m) {
      &:nth-child(3n+1) {
        width: calc(((100% - 60px) / 3) + 15px);
        article {
          padding-left: 0px;
          padding-right: 15px;
          border-left: none;
          border-right: 1px solid $light-gray;
        }
      }
      &:nth-child(3n+2) {
        width: calc(((100% - 60px) / 3) + 30px);
        article {
          padding-left: 15px;
          padding-right: 15px;
          border-left: 1px solid $light-gray;
          border-right: 1px solid $light-gray;
        }
      }
      &:nth-child(3n+3) {
        width: calc(((100% - 60px) / 3) + 15px);
        article {
          padding-left: 15px;
          padding-right: 0px;
          border-left: 1px solid $light-gray;
          border-right: none;
        }
      }
      &.views-row-7,
      &.views-row-8,
      &.views-row-9 {
        border-bottom: none;
      }
    }

    &.views-row-last {
      border-bottom: none;
    }

    &.views-row-last article {
      border-right: none;
    }
  }

  &.view-display-id-attachment_1 {
    .views-row {
      width: 100%;
      article {
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
        padding-right: 0;
        border: 1px solid $grey-medium-lighter;
        img {
          display: block;
        }

        @include breakpoint($screen-m) {
          flex-direction: row;
        }
      }
    }
  }
}
.block-views {
  .view-blog {
    margin: -60px auto 60px auto;
    padding-bottom: 80px;
    max-width: 1200px;
    background-color: $white;
    .views-row {
      border-bottom: none;
    }
    .more-link {
      padding-top: 40px;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      a {
        @extend %button;
      }
    }
    &.view-display-id-blog_preview {
      margin: -50px auto 50px auto;
      padding: 0px 25px 0px 25px;
      max-width: 1440px;
      @include breakpoint($screen-xl) {
        margin: -60px auto 112px auto;
        padding: 0px 64px 0px 64px;
      }
      .view-content {
          width: 100%;
      }
    }
  }
}
