.block-paradigm-notebook-notebook-link {
  display: none;
  @include breakpoint($screen-m) {
    display: block;
  }
  .notebook-link {
    display: block;
    font-size: 18px;
    line-height: 20px;
    padding: 15px 9px 14px 9px;

    &::first-letter {
      font-size: 20px;
      font-family: $ivory-headline;
    }
  }
}
