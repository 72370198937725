.node-type-video-link-paragraphs-index-item {

  .entity-view-mode--video-link-interview {
    .field-name-field-headline {
      &:after {
        content: 'Interview';
      }
    }
    .field-name-field-index-transcripts {
      &:before {
        content: "Video Transcript";
      }
    }
  }

  .entity-view-mode--video-link-discussion {
    .field-name-field-headline {
      &:after {
        content: 'Discussion';
      }
    }
    .field-name-field-index-transcripts {
      &:before {
        content: "Video Transcript";
      }
    }
  }

  .entity-view-mode--video-link-performance {
    .field-name-field-headline {
      &:after {
        content: 'Performance';
      }
    }
  }

  .field-name-field-headline {
    &:after {
      font-size: 0.8em;
    }
  }

  .field-name-field-index-introduction {
    padding-left: 0px;
  }

  .group-excerpt {
    margin-top: 50px;
  }

  .field-name-field-index-transcripts,
  .lines-renderer-wrapper {
    &:before {
      content: "Shakespeare's Text";
      @extend h3.field-label;
    }
  }
}
