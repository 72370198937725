.page-search {
  &.admin-menu .page-wrapper {
    padding-top: 160px;
  }
}
.view-search-index-pages {
  width: 95%;
  margin: auto;
  .view-content {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    .views-row-link {
      width: 33%;
      box-sizing: border-box;
      padding-right: 5px;
      margin-bottom: 50px;
    }
  }
  #views-exposed-form-search-index-pages-page {
    .views-exposed-widgets {
      display: flex;
      flex-wrap: wrap;
      .views-exposed-widget {
        float: none;
        padding: 0px;
        label {
          font-weight: normal;
          margin-bottom: 5px;
          line-height: 1.1em;
        }
        input[type="text"] {
          background-color: $grey-lightest;
          border: none;
          box-shadow: none;
          margin-bottom: 0px;
          &::placeholder {
            color: $medium-gray;
          }
        }
      }
      #edit-search-api-views-fulltext-wrapper {
        flex: 1 1 70%;
        margin-bottom: 15px;
        margin-right: 0px;
        padding-right: 10px;
      }
      #edit-sort-bef-combine-wrapper {
        width: calc(100%/3);
        padding-right: 10px;
        margin-bottom: 15px;
        box-sizing: border-box;
        @media all and (max-width: $screen-m) {
          width: 100%;
          padding-right: 0;
        }
        .form-select {
          width: 100%;
        }
        select {
          max-width: 100%;
          margin-bottom: 0;
          padding: 6px 9% 6px 10px;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          background-color: #f0f0f0;
          background-image: url(../images/chevron-down.png);
          background-repeat: no-repeat;
          background-size: 10px;
          background-position: 95% 18px;
          border: none;
          outline: none;
        }
      }
      .chosen-container {
        background-image: url(../images/chevron-down.png);
        background-repeat: no-repeat;
        background-size: 10px;
        background-position: 95% 18px;
        background-color: $grey-lightest;
        border-radius: 3px;
        .form-select {
          width: 100%;
        }
      }
      #edit-field-play-term-wrapper,
      #edit-field-act-wrapper,
      #edit-field-scene-wrapper,
      #edit-field-index-search-subtitle-wrapper {
        padding-right: 10px;
        margin-bottom: 15px;
        box-sizing: border-box;
        &.views-widget-filter-field_index_search_subtitle {
          margin-bottom: 0px;
        }
        .chosen-container {
          width: 100% !important;
        }
        .chosen-choices {
          border: none;
          padding: 10px 9% 10px 10px;
          background-image: none;
          background-color: transparent;
          border-radius: 3px;
          .chosen-search-input {
            font-size: 1.2em;
            font-family: $baskerville;
            &::placeholder {
              color: $medium-gray;
            }
          }
          .search-choice {
            border: 1px solid $red;
            background-image: none;
            margin: 2px 5px 2px 0;
            padding: 1px 18px 1px 3px;
            font-size: 12px;
            span {
              font-family: $baskerville;
              font-size: 1.2em;
              line-height: 1.2em;
              color: $red;
            }
            .search-choice-close {
              top: 6px;
              background: url(../images/chosen-close.png) no-repeat !important;
              &:hover {
                background: url(../images/chosen-close-dark.png) no-repeat !important;
              }
            }
          }
          .search-field {
            margin-top: 5px;
          }
        }
        .chosen-drop {
          li {
            font-family: $baskerville;
            font-size: 1.2em;
            line-height: 2.5em;
          }
          .highlighted {
            background-color: $grey-lightest;
            background-image: none;
            color: $black;
          }
        }
      }
      #edit-field-play-term-wrapper,
      #edit-field-act-wrapper,
      #edit-field-scene-wrapper{
        width: calc(100%/3);
      }
      #edit-field-index-search-subtitle-wrapper {
        width: 100%;
        .bef-toggle {
          position: relative;
          top: -30px;
          left: calc(100% - 100px);
        }
        .bef-checkboxes {
          display: flex;
          flex-wrap: wrap;
          position: relative;
          top: -15px;
          input[type="checkbox"] {
            display: none;
          }
          label {
            padding-left: 24px;
            &::before {
              content: '';
              display: block;
              position: relative;
              top: 15px;
              left: -25px;
              height: 15px;
              width: 15px;
              background-color: $light-gray;
              border: 1px solid $black;
            }
          }
          input:checked + label::before {
            background-image: url('../images/check-red.svg');
            background-size: 9px;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        .option {
          font-size: 14px;
        }
        .form-type-bef-checkbox {
          display: flex;
          align-items: flex-start;
          width: 100%;
          padding-right: 10px;
          padding-bottom: 10px;
          @include breakpoint($screen-s) {
            width: 50%;
          }
          @include breakpoint($screen-m) {
            width: 25%;
          }
        }
      }
      .views-reset-button {
        background-color: $white;
        border: $red 1px solid;
        margin-top: 22px;
        margin-bottom: 16px;
        border-radius: 3px;
        @media all and (max-width: $screen-s) {
          width: calc(50% - 10px);
        }
        .form-submit {
          padding: 0.8em 1em;
          margin-top: 0px;
          width: 150px;
          background-color: transparent;
          color: $red;
          @media all and (max-width: $screen-s) {
            width: 100%;
          }
        }
      }
    }
  }
  @media all and (max-width: $screen-m) {
    .view-content .views-row-link {
      width: 50%;
    }
    #views-exposed-form-search-index-pages-page {
      .views-exposed-widgets {
        #edit-search-api-views-fulltext-wrapper,
        #edit-field-play-term-wrapper,
        #edit-field-act-wrapper,
        #edit-field-scene-wrapper,
        #edit-field-index-search-subtitle-wrapper {
          width: 100%;
          padding-right: 0px;
          margin-bottom: 10px;
        }
        #edit-field-scene-wrapper {
          padding-right: 0px;
        }
      }
    }
  }

  .views-submit-button {
    margin: 22px 10px 0px 0px;
    .form-submit {
      margin-top: 0px;
      padding: 0.9em 1em;
      width: 150px;
      @media all and (max-width: $screen-s) {
        width: 100%;
      }
    }
    @media all and (max-width: $screen-s) {
      width: 50%;
    }
  }
  @media all and (max-width: $screen-s) {
    .view-content .views-row-link {
      width: 100%;
    }
  }
}
