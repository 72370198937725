.view-mode-full-node-type-credits-page {
  padding: 0 20px;
}

.node-type-credits-page {
  .page-title {
    color: $black;
    .subtitle {
      color: $grey-dark;
      font-size: 40px;
      font-weight: 600;
      line-height: 40px;
    }
  }
  .field-name-field-paragraphs {
    margin: 0 -8px;
    width: 100%;
  }
  .masonry-grid-sizer,
  .field-name-field-paragraphs {
    width: 100%;
    margin-bottom: 15px;
    box-sizing: border-box;
    @include breakpoint($screen-m) {
      width: 50%;
      padding: 0 15px;
    }
  }
}
