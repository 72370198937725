.node-what-s-happening,
.node-quick-study-page {
  .paragraphs-items-field-characters-references {
    margin-bottom: 20px;
  }
  .field-name-field-characters-references {
    color: $red;
    cursor: pointer;
    &, & div {
      display: inline-block;
    }
    .character-reference-tooltip {
      display: none;
    }
    &:hover {
      color: $dark-red;
    }
    .field-item {
      &:not(:last-of-type) {
        .character-title {
          &:after {
            content: ',\00a0';
            color: $black;
          }
        }
      }
    }
  }
}

.node-type-character-tooltip {
  display: flex;
  .field-name-field-description {
    flex: 1 1 70%;
  }
  .field-name-field-image {
    margin-right: 20px;
    flex: 1 1 30%;
  }
}
