.paragraphs-item-blog-full-width-image {
  margin-bottom: 30px;
  img {
    display: block;
  }
  .field-name-field-caption {
    margin-top: 18px;
    margin-bottom: 50px;
    margin-left: 20%;
    padding-left: 15px;
    border-left: 2px solid $red;
    color: $grey-neutral;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.233px;
  }
}