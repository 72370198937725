.view-mode-teaser-node-type-blog-post {
  .group-blog-image-wrapper {
    height: 0px;
    padding-bottom: 50%;
    overflow: hidden;
  }
  .group-content-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
  .group-blog-teaser-content {
    position: relative;
    margin-top: -15px;
    padding: 25px 10px 15px;
    background-color: $white;
    text-align: center;
  }
  .group-category-wrapper {
    margin-top: -44px;
  }
  .field-name-field-blog-category {
    position: relative;
    display: inline-block;
    margin-bottom: 12px;
    padding: 4px 24px;
    color: $white;
    background-color: $red;
    font-size: 12px;
    letter-spacing: 0.22px;
    line-height: 1.5;
    &:after, &:before {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      top: 0;
      border-style: solid;
      border-width: 0;
    }
    &:before {
      border-color: $red transparent;
      border-width: 13px 0 13px 8px;
      left: -8px;
    }
    &:after {
      border-color: $red transparent;
      border-width: 13px 8px 13px 8px;
      right: -8px;
    }
  }
}

.view-mode-featured-teaser-node-type-blog-post {
  .field-name-field-blog-image {
    .field-items,
    .field-item,
    img {
      height: 100%;
    }
    img {
      object-fit: cover;
    }
  }

  .field-name-field-featured-blog-story {
    text-align: right;
    .field-item {
      position: relative;
      display: inline-block;
      padding: 4px 32px;
      background-color: $grey-neutral;
      color: $white;
      font-size: 12px;
      letter-spacing: 0.22px;
      line-height: 1.5;
      vertical-align: top;
      &:before {
        content: '';
        position: absolute;
        height: 0;
        width: 0;
        top: 0;
        border-style: solid;
        border-width: 0;
        border-color: $grey-neutral transparent;
        border-width: 13px 0 13px 8px;
        left: -8px;
      }
    }
  }
  .group-featured-meta.field-group-div {
    display: flex;
    justify-content: space-between;
  }
  .field-name-field-blog-category {
    margin-bottom: 15px;
    color: $red;
    font-size: 10px;
    letter-spacing: 0.25px;
    line-height: 1.6;
    text-align: right;
  }
  .group-featured-inner-wrapper {
    padding: 20px 30px 50px;
    @include breakpoint($screen-m) {
      min-width: 330px;
    }
  }
}

.view-mode-teaser-node-type-blog-post,
.view-mode-featured-teaser-node-type-blog-post {
  > h2 {
    display: none;
  }
  .field-name-authored-date,
  .field-name-field-blog-author {
    margin-bottom: 15px;
    font-size: 10px;
    letter-spacing: 0.25px;
    line-height: 1.6;
  }
  .field-name-field-blog-author {
    margin-top: -10px;
  }
  .field-name-teaser-title {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.338px;
    line-height: 1.33;
    a {
      text-decoration: underline;
    }
  }
  .field-name-field-blog-summary {
    margin-bottom: 15px;
    font-size: 14px;
    letter-spacing: 0.233px;
    line-height: 1.43;
  }
  .field-name-read-more {
    font-size: 14px;
    letter-spacing: 0.233px;
    line-height: 1.43;
    a {
      text-decoration-line: underline;
    }
  }
}