.paragraphs-item-statistics .field-name-field-statistics {
  padding-left: 25px;
  padding-right: 25px;
  margin: 5px auto 50px;
  text-align: left;
  @include breakpoint($screen-xl) {
    margin: 80px auto 112px;
    max-width: 1362px;
  }
  > .field-items {
    display: flex;
    align-items: flex-start;
    gap: 48px;
    align-self: stretch;
    flex-direction: column;
    @include breakpoint($screen-xl) {
      flex-direction: row;
    }
    > .field-item {
      @include breakpoint($screen-xl) {
        width: 25%;
      }
    }
  }
  .paragraphs-item-statistic {
    display: flex;
    padding-left: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    border-left: 2px solid $red;
    @include breakpoint($screen-l) {
      width: 100%;
    }
  }
  .field-name-field-number {
    color: $red;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }
  .field-name-field-text {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    }
}
