.art-type-annotation {
  position: relative;
  z-index: 100;
}

.annotation-wrapper {
  .field--annotation-note {
    textarea {
      height: 120px;
      border-color: $grey-light;
    }
  }
  .field-group--annotation-color .field {
    display: inline-block;
    @extend %styled-checkbox;

    label {
      font-size: 12px;
      padding-right: 5px;
      height: 25px;
    }
    label::before {
      background-color: $white;
      border-width: 2px;
      border-color: lighten($grey-lightest, 2);
      height: 22px;
      width: 22px;
    }
    input:checked + label::before {
      border-color: $grey-dark;
      background-image: url('../images/check.svg');
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: center;
    }
    input:checked + label::after {
      color: transparent;
    }
    &.field--annotation-color-yellow label::before {
      background-color: $yellow;
    }
    &.field--annotation-color-blue label::before {
      background-color: $blue-light;
    }
    &.field--annotation-color-pink label::before {
      background-color: $pink;
    }
    &.field--annotation-color-green label::before {
      background-color: $green-medium;
    }
  }
  .react-tags {
    margin-top: 15px;
    margin-bottom: 30px;
  }
  .form-submit {
    @extend %small-button;
  }
  .delete-button {
    margin-left: 14px;
    font-size: 12px;
  }
}

#art-create-annotation-link {
  position: absolute;
  top: -36px;
  right: -10px;
  cursor: pointer;
  font-weight: bold;
  height: 40px;
  width: 40px;
  border-radius: 15px;
  background: transparent url('../images/annotation.svg') center center no-repeat;
  background-size: 30px;
  z-index: 990;

  &.is-disabled {
    background: transparent url('../images/annotation-disabled.svg') center center no-repeat;
    background-size: 30px;
  }

  &.touch {
    background: transparent url('../images/annotation-big-right.svg') center center / 52px no-repeat;
    border-radius: 0;
    height: 52px;
    right: 10px;
    top: 0;
    width: 52px;
    // This adds an outline to annotation icon so it's clear whether it's on
    // top of other icon on mobile version.
    filter: drop-shadow($white 1px 1px 0px)
            drop-shadow($white 0px -1.5px 0px);

    @include breakpoint($screen-m) {
      right: 49px;
    }
  }
  &.touch.is-disabled {
    background: transparent url('../images/annotation-big-right-disabled.svg') center center / 52px no-repeat;
  }
}

.is-annotation-bg {
  cursor: pointer;

  &.annotation-bg-yellow {
    background-color: $yellow-highlight;
  }
  &.annotation-bg-blue {
    background-color: $blue-highlight;
  }
  &.annotation-bg-green {
    background-color: $green-highlight;
  }
  &.annotation-bg-pink {
    background-color: $pink-highlight;
  }
  &.annotation-bg-grey {
    background-color: $grey-highlight;
  }
  &.annotation-bg-yellow.annotation-bg-blue {
    background-color: $yellow-blue-highlight;
  }
  &.annotation-bg-yellow.annotation-bg-green {
    background-color: $yellow-green-highlight;
  }
  &.annotation-bg-yellow.annotation-bg-pink {
    background-color: $yellow-pink-highlight;
  }
  &.annotation-bg-blue.annotation-bg-green {
    background-color: $blue-green-highlight;
  }
  &.annotation-bg-blue.annotation-bg-pink {
    background-color: $blue-pink-highlight;
  }
  &.annotation-bg-green.annotation-bg-pink {
    background-color: $green-pink-highlight;
  }
}

.annotation-icons {
  position: absolute;
  width: 60px;
  margin-left: -65px;
  text-align: right;

  a {
    position: relative;
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 9px;
    margin-left: 5px;
    z-index: 100;
    border: solid 1px $grey-medium-darker;

    &.annotation-bg-yellow {
      background-color: $yellow;
    }
    &.annotation-bg-blue {
      background-color: $blue-light;
    }
    &.annotation-bg-pink {
      background-color: $pink;
    }
    &.annotation-bg-green {
      background-color: $green-medium;
    }
  }
}
