.paragraphs-item-text-and-cta {
  display: flex;
  justify-content: center;
  overflow: hidden;
  .content {
    padding: 50px 25px;
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    align-items: center;
    gap: 25px;
    @include breakpoint($screen-xl) {
      padding: 112px 64px;
      flex-direction: row;
      gap: 80px;
    }
    .group-image-group {
      order: -1;
      @include breakpoint($screen-xl) {
        order: 1;
      }
    }
    .group-text-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
      @include breakpoint($screen-xl) {
        max-width: 403px;
        min-width: 403px;
      }
      .field-items .field-item p:last-child {
        margin-bottom: 0px;
      }
    }
  }
  &.no-image {
    .content {
      align-items: flex-start;
      width: 100%;
      .group-text-group {
        max-width: 710px;
      }
    }
  }
  &.image-left-align {
    .content {
      gap: 0px;
    }
    .group-text-group {
      display: flex;
      padding: 25px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex: 1 0 0;
      @include breakpoint($screen-xl) {
        padding: 64px;
        max-width: 592px;
      }
    }
    .group-image-group {
      order: -1;
    }
    .field-name-field-section-heading {
      margin-top: 16px;
    }
    .field-name-field-section-text {
      margin: 40px 0px;
    }
  }
  &.wide-image:not(.image-left-align) {
    justify-content: flex-end;
    max-height: 400px;
    @include breakpoint($screen-xl) {
      max-height: 768px;
    }
    .content {
      padding: 25px 0px 50px 25px;
      width: 100%;
      max-width: 100vw;
      gap: 0px;
      flex-direction: row;
      @include breakpoint($screen-xl) {
        padding: 60px 0px 60px 64px;
        width: auto;
        gap: 80px
      }
    }
    .group-text-group {
      padding: 0px;
      margin-bottom: 25px;
      align-self: start;
      max-width: 786px;
      @include breakpoint($screen-xl) {
        align-self: center;
      }
    }
    .field-name-field-section-eyebrow-heading {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    .field-name-field-section-heading {
        margin: 24px 0px 40px 0px;;
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        @include breakpoint($screen-xl) {
          font-size: 56px;
        }
    }
    .group-image-group {
      align-self: center;
      order: 1;
      img {
        @include breakpoint($screen-xl) {
          max-width: 37vw;
          min-width: 37vw;
        }
      }
    }
  }
  &.wide-image.image-left-align {
    justify-content: flex-start;
    @include breakpoint($screen-xl) {
      max-height: 768px;
    }
    .content {
      padding: 0px;
      flex-direction: column;
      @include breakpoint($screen-xl) {
        flex-direction: row;
      }
    }
    .group-text-group {
      padding: 25px;
      @include breakpoint($screen-xl) {
        padding: 64px;
        min-width: 720px
      }
    }
    .group-image-group {
      @include breakpoint($screen-xl) {
        margin-top: 0px;
      }
      img {
        @include breakpoint($screen-xl) {
          max-width: 50vw;
          min-width: 50vw;
        }
      }
    }
  }
  &.dark-background {
    background-color: $darker-red;
   .field-name-field-section-eyebrow-heading {
     color: $light-coral;
   }
   .field-name-field-section-heading,
   .field-name-field-section-text {
     color: $white;
   }
  }
  &.dark-background.wide-image {
    justify-content: flex-end;
    @include breakpoint($screen-xl) {
      max-height: 768px;
    }
    .content {
      padding: 25px 0px 0px 25px;
      width: 100%;
      max-width: 100vw;
      gap: 0px;
      @include breakpoint($screen-xl) {
        padding: 60px 0px 60px 64px;
        width: auto;
        gap: 80px
      }
    }
    .group-text-group {
      max-width: 786px;
      padding: 0px;
      align-self: start;
      @include breakpoint($screen-xl) {
        align-self: center;
      }
    }
    .group-image-group {
      @include breakpoint($screen-xl) {
        align-self: center;
      }
      img {
        @include breakpoint($screen-xl) {
          max-width: 37vw;
          min-width: 37vw;
        }
      }
    }
  }
  .field-name-field-section-eyebrow-heading {
    font-size: 18px;
    line-height: 150%;
    font-weight: bold;
    color: $crimson-red;
  }
  .field-name-field-section-heading {
    margin-top: 16px;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    @include breakpoint($screen-xl) {
      font-size: 56px;
    }
  }
  .field-name-field-section-text {
    margin: 24px 0px;
    color: $black;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  .group-cta-group > .field-name-field-section-ctas > .field-items {
    display: flex;
    flex-direction: column;
    @include breakpoint($screen-xl) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .field-name-field-section-ctas {
    a {
      @extend %button;
    }
  }
  &.single-light {
    background-color: $white;
    .field-name-field-section-ctas .field-item {
      width: 100%;
    }
    .group-text-group {
      align-items: center;
    }
  }
  &.double-dark {
    max-width: 100%;
    background-color: $grey-lightest;
    .field-name-field-section-ctas .field-items {
      gap: 25px;
    }
  }
}
