.breadcrumb {
  ///////////////////////////////////////////////////////////////////////////////////
  $base-border-color: gainsboro !default;
  $base-border-radius: 3px !default;
  $base-background-color: white !default;
  $base-spacing: 1.5em !default;
  $base-accent-color: #477DCA !default;
  $base-link-color: $base-accent-color !default;
  $medium-gray: #999 !default;
  //////////////////////////////////////////////////////////////////////////////////

  $breadcrumb-border: none;
  $breadcrumb-height: $base-spacing * 0.75;
  $breadcrumb-arrow-color: $red;
  $breadcrumb-background: $base-background-color;
  $breadcrumb-inactive-hover-color: $breadcrumb-background;
  $breadcrumb-color: $red;
  $breadcrumb-color-hover: $red;
  $breadcrumb-color-active: $breadcrumb-color;

  display: inline-block;
  text-align: left;
  position: relative;
  left: initial;
  margin-bottom: 0;

  @include breakpoint($screen-l) {
    position: absolute;
    left: 168px;
    margin-bottom: $base-spacing;
  }

  a {
    background-color: $breadcrumb-background;
    border: $breadcrumb-border;
    color: $breadcrumb-color;
    display: inline-block;
    font-size: 0.75em;
    line-height: $breadcrumb-height;
    margin-bottom: 2px;
    margin-right: -5px;
    padding: 0 ($breadcrumb-height / 4) 0 ($breadcrumb-height);
    position: relative;
    text-decoration: none;

    &:first-child {
      border-bottom-left-radius: $base-border-radius;
      border-left: $breadcrumb-border;
      border-top-left-radius: $base-border-radius;
      padding-left: $breadcrumb-height / 2;
    }

    &:last-child {
      background-color: $breadcrumb-background;
      border-bottom-right-radius: $base-border-radius;
      border-top-right-radius: $base-border-radius;
      color: $breadcrumb-color-active;
      padding-right: $breadcrumb-height / 2;
      border-left: none;
    }

    &:focus,
    &:hover {
      background-color: $breadcrumb-inactive-hover-color;
      color: $breadcrumb-color-hover;
    }

    &:after,
    &:before {
      @include position(absolute, 0px auto 0px 100%);
      content: '>';
      display: block;
      margin: auto;
      z-index: 2;
    }

    &:last-child:after,
    &:last-child:before {
      border: none;
      content: "";
    }

    &:before {
      border-left-color: $breadcrumb-arrow-color;
      margin-left: 1px;
      z-index: 1;
    }
    &:after {
      border-left-color: $breadcrumb-background;
    }
  }
}
