.react-tags {
  position: relative;
  padding: 3px 0 0 5px;
  background-color: $white;
  border: 1px solid $grey-light;
  border-radius: 3px;
  font-size: 12px;
  line-height: 1.2;
  cursor: text;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 0 0;
  padding: 6px 8px;
  background: $red;
  font-size: inherit;
  line-height: inherit;

  &:after {
    content: '\2715';
    margin-left: 8px;
  }

  &:hover,
  &:focus {
    background: $dark-red;
  }
}

.react-tags__search {
  display: inline-block;
  padding: 7px 2px;
  margin-bottom: 4px;
  max-width: 100%;

  @include breakpoint($screen-s) {
    position: relative;
  }

  input {
    max-width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    font-size: inherit;
    line-height: inherit;
    box-shadow: none;

    &:focus {
      box-shadow: none;
      border-color: none;
    }

    &::-ms-clear {
      display: none;
    }
  }
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  @include breakpoint($screen-s) {
    width: 240px;
  }

  ul {
    margin: 4px -1px;
    padding: 0;
    list-style-type: none;
    background: white;
    border: 1px solid $grey-lighter;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }

  li {
    border-bottom: 1px solid $grey-lighter;
    padding: 6px 8px;
    list-style-type: none;
    mark {
      text-decoration: underline;
      background: none;
      font-weight: 600;
    }

    &:hover {
      cursor: pointer;
      background: $grey-lightest;
    }

    &.is-active {
      background: $grey-lighter;
    }

    &.is-disabled {
      opacity: 0.5;
      cursor: auto;
    }
  }
}
