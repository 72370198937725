.art-type-popup-note {
  width: auto;
  &.icon--quill_pen .art-icon {
    background: url('../images/icon-word-nerd.png') 50% 50% no-repeat;
    background-size: 99% 99%;
  }
  &.icon--speech_bubble .art-icon {
    background: url('../images/icon-chat-bubble.png') 50% 50% no-repeat;
    background-size: 99% 99%;
  }
}
.popup-note-content {
  @include clearfix;

  .field-name-field-popup-heading {
    margin-bottom: 15px;
    p {
      margin-bottom: 0;
    }
  }
  .field-name-field-popup-body {
    p:last-child {
      margin-bottom: 0;
    }
  }
  .field-name-field-image {
    float: right;

    &.image-aspect-square,
    &.image-aspect-tall {
      width: 38%;
      padding: 0px 20px;
    }

    &.image-aspect-wide {
      width: 60%;
      padding: 0 10px;
    }
  }
}
