.react-slider {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  .react-slider-slide {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(120%);
  }
  &.has-pager {
    .react-slider-slide {
      top: 20px;
    }
  }
}
.slider-pager {
  display: flex;
}
.slider-pager-prev {
  margin-right: auto;
  cursor: pointer;
}
.slider-pager-next {
  margin-left: auto;
  cursor: pointer;
  &.is-disabled,
  &.is-disabled:hover {
    color: $grey-light;
    cursor: default;
  }
}
