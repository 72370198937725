.gloss {
  display: inline-block;
  position: relative;
  font-weight: bold;
  &.has-quiz {
    color: $red;
    cursor: pointer;
    padding-right: 12px;
    .gloss-def {
      margin-left: -6px;
    }
    &:after {
      position: absolute;
      display: block;
      right: 0px;
      top: 0;
      content: "";
      font-size: 12px;
      text-align: center;
      color: $white;
      width: 12px;
      height: 12px;
      background: url('../images/gloss-icon-active.svg') 50% 50% no-repeat;
      background-size: 12px 12px;
    }
    &.disabled {
      color: $black;
      &:after {
        background: url('../images/gloss-icon-disabled.svg') 50% 50% no-repeat;
      }
    }
  }
  &.gloss-eol.has-quiz {
    padding-right: 0;
    .gloss-def {
      margin-left: 0;
    }
    &:after {
      right: -14px;
    }
  }
}
.gloss-def {
  position: absolute;
  top: 26px;
  left: -300%;
  right: -300%;
  text-align: center;
  font-size: rem(12px);
  line-height: rem(12px);
  font-weight: normal;
  color: #666;
  &:before {
    display: inline-block;
  }
}
.gloss-text-left {
  .gloss-def {
    text-align: left;
    left: 0;
  }
}
.gloss-missing-word,
.gloss-missing-word-eol {
  .gloss-text {
    font-weight: normal;
  }
}
.field-name-field-characters {
  .gloss {
    cursor: pointer;
    &:hover {
      color: $red;
    }
  }
  .gloss-def {
    position: static;
    top: auto;
    right: auto;
    text-align: left;
    display: none;
  }
}
