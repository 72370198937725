.faq-page {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  .views-js-controls {
    text-align: right;
    margin-bottom: 30px;
    font-weight: bold;
  }
}