html, body {
  height: 100%;
}
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  main {
    @include outer-container;
    padding: 0 0;
  }
  .page {
    position: relative;
    min-height: 100%;
  }
  .page-wrapper {
    padding-top: 60px;
  }
  .admin-bar & .page-wrapper {
    padding-top: 100px;
  }
  &:has(.quick-nav) {
    .page-wrapper {
      padding-top: 110px;
    }
  }
  .admin-bar & {
    .page.has-quick-nav {
      .page-wrapper {
        padding-top: 180px;
      }
    }
  }
  .admin-bar &.notebook-export .page-wrapper {
    padding-top: 0;
  }
  &.no-sidebars {
    main {
      section#content {
        @include span-columns(12);
      }
    }
  }

  &.one-sidebar.sidebar-first {
    main {
      aside {
        &#sidebar-first {
          @include span-columns(4);
        }
      }

      section#content {
        @include span-columns(8);
      }
    }
  }

  &.one-sidebar.sidebar-second {
    main {
      aside {
        &#sidebar-second {
          @include span-columns(4);
        }
      }

      section#content {
        @include span-columns(8);
      }
    }
  }

  &.two-sidebars {
    main {
      aside {
        &#sidebar-first {
          @include span-columns(3);
        }

        &#sidebar-second {
          @include span-columns(3);
        }
      }

      section#content {
        @include span-columns(6);
      }
    }
  }
}

.outer-wrapper {
  @include outer-container;
}
