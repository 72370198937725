.block--unverified-account {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px 20px 3px 20px;
  border: 2px solid $red;
  border-radius: 4px;
  background: $red-light;
  color: $red;
  font-weight: bold;
  text-align: center;
  a {
    text-decoration: underline;
  }
}
