.art-type-modern-english {
  width: auto;
  .art-icon {
    background: url('../images/icon-modern-english.png') 50% 50% no-repeat;
    background-size: 100% 100%;
  }
}
.modern-english-content {
  .field-name-field-body {
    p:last-child {
      margin-bottom: 0;
    }
  }
}
