.notebook-loading-indicator-wrapper {
  position: absolute;
  top: -20px;
  left: -20px;
  right: 20px;
  bottom: -20px;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  z-index: 999;
  background-color: rgba(200, 200, 200, .8);
  z-index: 10;
}
.notebook-loading-indicator {
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  max-width: 100vw;
  font-size: 16px;
  color: $red;
  font-weight: 700;
  text-align: center;
  line-height: 50px;
}
.notebook-loading-indicator--export {
  position: sticky;
  top: 150px;
  left: auto;
  line-height: 1.2;
  @include breakpoint(500px) {
    width: 500px;
  }
}
.notebook-loading-indicator-message {
  display: inline-block;
  text-align: center;
  background: url('../images/loading-pulse.gif') 0% 50% no-repeat;
  background-size: 50px 50px;
  min-height: 50px;
  padding: 10px 10px 10px 65px;
}
