#edit-account {
  .password-strength {
    display: none;
  }
  .password-suggestions {
    display: none !important;
  }
  .password-confirm {
    span {
      font-weight: bold;
    }
    .ok {
      color: $green;
    }
    .error {
      color: $red;
    }
  }
}
