.node-type-quick-study-page {
  background-color: #e6e6e6;
  line-height: 1.525;
  .page-wrapper {
    max-width: 785px;
    .page-title {
      @media all and (max-width: $screen-l) {
        display: none;
      }
    }
    .node-quick-study-page {
      margin-bottom: 2em;
      .field-name-field-subtitle {
        text-align: center;
        font-style: italic;
        margin-bottom: 1em;
      }

      @media all and (max-width: $screen-l) {
        padding: 0 20px;
      }
    }
  }
  h3.field-label {
    color: $black;
  }
  .field-name-field-characters .gloss-text {
    color: $red;
    &:hover {
      color: $black;
    }
  }
}
