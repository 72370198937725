.paragraphs-item-feature-preview {
  padding: 50px 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 64px;
  background-color: $white-smoke;
  text-align: center;
  @include breakpoint($screen-m) {
    padding: 112px 64px;
  }
  > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    width: 1440px;

    > .field-name-field-image {
      max-width: 1000px;
      img {
        margin-bottom: -17%;
        margin-top: -5%;
      }
    }
  }
  .field-name-field-eyebrow-heading {
    color: $red;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  .field-name-field-heading {
    max-width: 890px;
    color: $black;
    text-align: center;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    @include breakpoint($screen-m) {
      font-size: 56px;
    }
  }
  .field-name-field-body {
    max-width: 890px;
    color: $black;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  .field-type-paragraphs {
    > .field-items {
      margin-top: -12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      align-self: stretch;
      max-width: 890px;
      @include breakpoint($screen-m) {
        flex-direction: row;
        gap: 48px;
      }
    }
    .field-name-field-image {
      .field-item {
        max-height: 90px;
      }
    }
    .field-name-field-eyebrow-heading {
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    .field-name-field-heading {
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }
    .paragraphs-item-feature {
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
      }
    }
  }
  .field-name-field-link {
    margin-top: 40px;
    a {
      @extend %button;
    }
  }
  &.dark-background {
    background-color: $darker-red;
    .field-name-field-eyebrow-heading {
      color: $light-coral;
    }
    .field-name-field-heading {
      color: $white;
    }
    .field-name-field-body {
      color: $white;
    }
  }
}
