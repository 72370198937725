.block-paradigm-blog-blog-account-cta {
  margin-top: 70px;
  margin-bottom: 70px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;

  .blog-account-cta-text {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
    line-height: 35px;
  }

  .blog-account-cta-link {
    padding: 15px 30px;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.035px;
  }

  .blog-account-cta-button {
    display: flex;
    justify-content: center;
  }
}