.art-type-audio-player {
  width: auto;

  .art-icon {
    background: url('../images/icon-audio-popup-white.png') 50% 50% no-repeat, url('../images/icon-audio-popup-background.png') 50% 50% no-repeat;
    background-size: 60%, 100%;

    &.play {
      background: url('../images/icon-audio-popup-pause-white.png') 50% 50% no-repeat, url('../images/icon-audio-popup-background.png') 50% 50% no-repeat;
      background-size: 60%, 100%;
    }
  }
}
