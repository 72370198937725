.block-menu-pager {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  @include breakpoint($screen-m) {
    width: 100%;
    padding: 0 20px;
  }
  @include breakpoint($screen-l) {
    width: 50%;
    padding: 0;
  }

  .video-link {
    color: $red;
    font-size: 0.75em;
    display: block;
    padding-top: 10px;
    &:hover {
      cursor: pointer;
    }
  }
}
