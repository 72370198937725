.block-paradigm-rc-quiz {
  margin-bottom: 30px;
  > h2 {
    @extend %h3;
    text-align: center;
    margin-bottom: 9px;
  }
}
.paradigm-rc-quiz-already-submitted,
.paradigm-rc-take-quiz-link-wrapper {
  text-align: center;
}
.paradigm-rc-quiz-link,
.paradigm-rc-quiz-title-wrapper {
  display: flex;
  justify-content: space-between;
}
.paradigm-rc-quiz-title {
  color: $red;
}
.paradigm-rc-take-quiz-header {
  @include line-section;
  font-size: rem(24);
  line-height: rem(35);
}
.paradigm-rc-quiz-icon {
  width: 11%;

}
.paradigm-rc-quiz-title {
  width: 87%;
  padding-left: 10px;
  padding-right: 10px;
}
.paradigm-rc-quiz-form {
  .form-item {
    margin-top: 0;
  }
  .form-type-radios {
    label {
      font-weight: normal;
      line-height: 1.25;
    }
  }
  .form-type-radio {
    @include clearfix();
    margin-bottom: 16px;
  }
  label p {
    margin-bottom: 0;
    line-height: 1.25;
  }
  .form-type-radios {
    > label {
      margin-bottom: 16px;
    }
  }
  input[type="radio"] {
    display: block;
    float: left;
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 1.25;
  }
  label.option {
    display: block;
    margin-bottom: 0;
    line-height: 1.25;
    font-weight: normal;
  }
}
.paradigm-rc-quiz-question {
  margin-bottom: 20px;
  > .form-item {
    padding-left: 15px;
  }
}
