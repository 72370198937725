.tabs {
  &.primary {
    ///////////////////////////////////////////////////////////////////////////////////
    $base-border-color: gainsboro !default;
    $base-background-color: white !default;
    $base-spacing: 1.5em !default;
    $base-accent-color: $red !default;
    $base-link-color: $base-accent-color !default;
    $dark-gray: #333 !default;
    //////////////////////////////////////////////////////////////////////////////////

    $tab-link-color: $dark-gray;
    $tab-border: 1px solid $base-border-color;
    $tab-active-background: $base-background-color;
    $tab-inactive-color: $base-background-color;
    $tab-inactive-hover-color: silver;
    $tab-mode: $screen-m;

    @include clearfix;
    line-height: 1.5;
    padding: 0;
    margin: 5px 0;
    border-bottom: 1px solid $base-border-color;

    li {
      list-style: none;

      @include breakpoint($tab-mode) {
        display: inline;
      }

      a {
        background-color: $tab-inactive-color;
        border-top: $tab-border;
        color: $tab-link-color;
        display: block;
        font-weight: 400;
        padding: ($base-spacing / 2) $gutter;
        text-decoration: none;

        @include breakpoint($tab-mode) {
          @include inline-block;
          border-top: 0;
        }

        &:hover {
          color: $base-link-color;
        }

        &:focus {
          outline: none;
        }

        &.active {
          border-bottom: 0;

          @include breakpoint($tab-mode) {
            border: $tab-border;
            border-bottom-color: $tab-active-background;
            margin-bottom: -1px;
          }
        }
      }

      .tab-content {
        display: none;
        padding: $base-spacing $gutter;
        width: 100%;

        @include breakpoint($tab-mode) {
          border-top: $tab-border;
          float: left;
        }
      }
    }
  }
}
