.view-play-list {
  padding: 20px 20px 0px 20px;

  .view-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  .views-row {
    a {
      position: relative;
      display: block;
    }
    img {
      display: block;
    }
  }
  .view-play-list-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $black;
    text-align: center;
    .view-play-list-header-text {
      max-width: 700px;
      padding-top: 35px;
      padding-bottom: 35px;
      font-size: 20px;
      line-height: 1.4;
      @include breakpoint($screen-m) {
        font-size: 43px;
        letter-spacing: 0.23px;
        line-height: 60px;
      }
    }
    .view-play-list-header-link {
      padding-bottom: 40px;
      padding-top: 0px;
      font-size: 16px;
      max-width: 611px;
      color: $black;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.444px;
      @include breakpoint($screen-m) {
        padding-bottom: 40px;
        font-size: 20px;
      }
    }
  }
}

.block-views-play-list-footer h2 {
  display: none;

  @include breakpoint($screen-l) {
    display: block;
    text-align: center;
    color: $red;
  }
}

.block-views-play-list-homepage,
.block-views-play-list-homepage-dark,
.block-views-play-list-inline {
  padding-bottom: 100px;
  .view-play-list {
    padding-top: 0px;
  }
  .view-play-list-header-link {
    padding-bottom: 40px;
    padding-top: 0px;
  }
  .view-content {
    padding: 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @include breakpoint(1425px) {
      max-width: 1425px;
      flex-wrap: nowrap;
    }
  }
  .views-row {
    box-sizing: border-box;
    width: 33.33%;
    @include breakpoint($screen-l) {
      width: 16.083%;
      padding-left: 25px;
      padding-right: 25px;
    }
    &.logo-Midsummer-Night039s-Dream {
      @include breakpoint($screen-l) {
        width: 19.585%;
      }
    }
  }
  .views-row {
    a:after {
      position: absolute;
      width: 130px;
      height: 3px;
      left: 50%;
      margin-left: -65px;
      content: '';
      bottom: 0;
      background-color: $white;
      z-index: 1;
    }
    a:hover:after {
      background-color: $red;
    }
  }
  .field-name-field-play-logo {
    width: 171px;
  }

  .field-name-field-play-logo {
    max-width: 100px;
    margin: 0 auto;
    @include breakpoint($screen-s) {
      width: 175px;
      max-width: none;
    }
    @include breakpoint($screen-m) {
      width: 175px;
    }
    @include breakpoint($screen-l) {
      width: 100%;
    }
  }
  .logo-Midsummer-Night039s-Dream .field-name-field-play-logo {
    // Midsummer needs to be 1.28 times the size of the other logos
    max-width: 128px;
    margin: 0 auto;
    @include breakpoint($screen-s) {
      width: 224px;
      max-width: none;
    }
    @include breakpoint($screen-m) {
      width: 224px;
    }
    @include breakpoint($screen-l) {
      width: 100%;
    }
  }
}
.block-views-play-list-homepage-dark {
  background-color: $white-smoke;
  .views-row {
    a:after {
      background-color: $white-smoke;
    }
    a:hover:after {
      background-color: $red;
    }
  }
}
.block-views-play-list-inline {
  padding-top: 20px;
  padding-bottom: 0px;
}
.block-views-play-list-navigation,
.block-views-play-list-footer {
  .view-content {
    flex-wrap: nowrap;
    max-width: none;
    justify-content: space-between;
  }
}
.block-views-play-list-navigation {
  .view-display-id-navigation {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @include breakpoint(1460px) {
      max-width: 1460px;
    }
  }
  .view-content {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .views-row {
    box-sizing: border-box;
    flex-shrink: 1;
    width: 16.0985%;
    padding-left: 1%;
    padding-right: 1%;
    @include breakpoint(1460px) {
      flex-shrink: 0;
      padding-left: 2.1%;
      padding-right: 2.1%;
    }
    &.logo-midsummer-nights-dream,
    &.logo-Midsummer-Night039s-Dream {
      width: 19.52%
    }
  }
  .field-name-field-play-logo {
    img {
      width: 100%;
    }
  }
}
.block-views-play-list-footer {
  .view-content {
    flex-wrap: wrap;
    @include breakpoint($screen-xl) {
      flex-wrap: nowrap;
    }
  }
  .views-row {
    @include breakpoint($screen-xl) {
      width: 20%;
    }
  }
}
.block-views-play-list-inline {
  margin-bottom: 120px;
  h1 {
    color: $black;
    text-align: center;
    margin-top: 40px;
  }
}
