#{$all-button-inputs},
%button,
button,
.button {
  @include appearance(none);
  padding: 12px 24px;
  -webkit-font-smoothing: antialiased;
  background-color: $action-color;
  border-radius: $base-border-radius;
  border: none;
  color: #fff;
  cursor: pointer;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  &:hover,
  &:focus {
    background-color: darken($action-color, 15%);
    color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

%small-button {
  @extend %button;
  padding: 2px 22px;
  height: 36px;
  font-size: 14px;
  line-height: 32px;
}

@mixin red-button {
  display: inline-block;
  text-align: center;
  background-color: $red;
  color: $white;
  border: 1px solid $white;
  border-radius: 7px;
  padding: 15px;
  font-size: 20px;
  line-height: 1;
  &:hover {
    background-color: $white;
    color: $red;
    border-color: $dark-red;
  }
}

@mixin back-to-play-button {
  display: flex;
  font-size: 1.2rem;
  line-height: 1.3;
  color: $red;
  width: 150px;
  text-align: right;
  float: right;

  &::after {
    content: '';
    display: inline-block;
    background-color: $red;
    background-image: url('../images/right-arrow-white.png');
    background-size: 25px;
    background-position: 55% 50%;
    background-repeat: no-repeat;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    flex: 0 0 auto;
    margin-left: 10px;
    transition: background-color 0.1s linear;
  }

  &:hover {
    &::after {
      background-color: $dark-red;
    }
  }
}
.back-to-play-button {
  @include back-to-play-button;
}

.back-to-blog {
  @include back-to-play-button;
  float: left;
  text-align: left;
  width: 80%;
  padding-top: 15px;
  &::after {
    content: none;
  }
  &::before {
    content: '';
    display: inline-block;
    background-color: $red;
    background-image: url('../images/left-arrow-white.png');
    background-size: 25px;
    background-position: 55% 50%;
    background-repeat: no-repeat;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    flex: 0 0 auto;
    margin-right: 10px;
    margin-top: -15px;
    transition: background-color 0.1s linear;
  }
}
