.view-mode-search-result-teaser {
  width: 100%;
  min-height: 60px;
  h2,
  .field-name-field-headline,
  .field-name-field-index-title {
    color: $action-color;
    font-size: 1.3em;
    line-height: 1.1em;
    font-weight: bold;
    text-align: left;
    margin-bottom: 0px;
    &:hover {
      color: $dark-red;
    }
  }
  .field-name-field-index-search-subtitle {
    color: $grey-medium-darker;
    font-size: 1em;
    line-height: 1.5em;
  }
  .field-name-field-play-term,
  .group-act-and-scene,
  .field-name-field-index-highlighted-lineref {
    display: block;
    text-align: left;
    line-height: 1.5em;
    font-size: 1em;
    margin-bottom: 0px;
    color: $black;
  }
  .group-act-and-scene {
    display: flex;
    justify-content: flex-start;
  }
  .field-name-field-play-term {
    &:before {
      content: '';
      display: block;
      width: 75px;
      height: 1px;
      background-color: $grey-lightest;
    }
  }
  &.node-popup-note-index-item .group-text-column,
  &.node-full-scene-modern-english .group-text-column,
  &.node-full-scene-audio .group-text-column,
  &.node-folio,
  &.node-quick-study-page,
  &.node-credits-page {
    background-size: 60px;
    background-repeat: no-repeat;
    padding-left: 80px;
  }
  &.node-popup-note-index-item {
    &.icon--quill_pen .group-text-column {
      background-image: url(../images/icon-word-nerd-small.png);
    }
    &.icon--speech_bubble .group-text-column {
      background-image: url(../images/icon-chat-bubble-small.png);
    }
  }
  &.node-full-scene-modern-english {
    .group-text-column {
      background-image: url(../images/icon-chat-bubble-small.png);
      &:before {
        content: 'Full Scene Modern English';
        color: $action-color;
        font-size: 1.3em;
        line-height: 1.05em;
        font-weight: bold;
        display: block;
      }
      .field-name-field-play-term {
        &:after {
          content: '';
          font-size: 0;
        }
      }
      &:hover {
        &:before {
          color: $dark-red;
        }
      }
    }
  }
  &.node-full-scene-audio {
    .group-text-column {
      background-image: url(../images/icon-audio-default-small.png);
      &:before {
        content: 'Full Scene Audio';
        color: $action-color;
        font-size: 1.3em;
        line-height: 1.05em;
        font-weight: bold;
        display: block;
      }
      &:hover {
        &:before {
          color: $dark-red;
        }
      }
    }
  }
  &.node-video-index-item,
  &.node-video-link-paragraphs-index-item {
    display: flex;
    .field-name-field-video-icon,
    .field-name-field-icon {
      margin-right: 20px;
      img {
        width: 60px;
        min-width: 60px;
      }
    }
  }
  &.node-video-link-paragraphs-index-item {
    .field-name-field-icon {
      img {
        width: auto;
        height: 60px;
        max-width:none;
      }
    }
  }
  &.node-quick-study-page {
    background-image: url(../images/quick_study_icon.png);
  }
  &.node-folio,
  &.node-credits-page {
    &[about*='macbeth'] {
      background-image: url(../images/my-macbeth-tilt.png);
    }
    &[about*='romeo-and-juliet'] {
      background-image: url(../images/my-romeo-tilt.png);
    }
    &[about*='julius-caesar'] {
      background-image: url(../images/my-julius-tilt.png);
    }
    &[about*='hamlet'] {
      background-image: url(../images/my-hamlet-tilt.png);
    }
  }
  @media all and (max-width: $screen-m) {
    &.node-popup-note-index-item .group-text-column,
    &.node-full-scene-modern-english .group-text-column,
    &.node-full-scene-audio .group-text-column,
    &.node-folio,
    &.node-quick-study-page,
    &.node-credits-page {
      padding-left: 70px;
    }
    &.node-video-index-item,
    &.node-video-link-paragraphs-index-item {
      .field-name-field-video-icon,
      .field-name-field-icon {
        margin-right: 10px;
      }
    }
  }
  @media all and (max-width: $screen-s) {
    &.node-popup-note-index-item .group-text-column,
    &.node-full-scene-modern-english .group-text-column,
    &.node-full-scene-audio .group-text-column,
    &.node-folio,
    &.node-quick-study-page,
    &.node-credits-page {
      padding-left: 80px;
    }
    &.node-video-index-item,
    &.node-video-link-paragraphs-index-item {
      .field-name-field-video-icon,
      .field-name-field-icon {
        margin-right: 20px;
      }
    }
  }
}
