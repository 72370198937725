.notebook-entries--wrapper {
  position: relative;
  min-height: 300px;
}
.notebook-entries {
  margin-top: 30px;
  th {
    padding: 10px 20px;
  }
  td {
    vertical-align: top;
    position: relative;
    padding: 20px;
    border-color: $grey-medium;
  }
  tbody tr:first-child td {
    border-top: 1px solid $grey-medium;
  }
}
.notebook-entries--header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 65px;
}
.notebook-entries--expand-collapse-links {
  a.is-active {
    font-weight: bold;
  }
  .separator {
    padding: 0px 10px;
    font-size: 18px;
    color: $grey-medium;
  }
}
.notebook-entries--count {
  font-size: 18px;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 0;
  margin-right: auto;
}
.notebook-entries--actions {
  display: flex;
  align-items: center;
}
.notebook-entries--messages {
  margin-top: 20px;
  .messages--error {
    @include flash($error-color);
  }
  .messages--success {
    @include flash($success-color);
  }
}
.notebook-entries--export {
  position: relative;
  margin-left: 20px;
  .button {
    position: relative;
    z-index: 2;
    padding-right: 35px;
    border: solid 2px $grey-medium;
    border-radius: 5px;
    &.is-loading {
      background: $red url('../images/loading-simple.gif') 100% 50% no-repeat;
      background-size: 30px 30px;
    }
    &.is-disabled {
      cursor: default;
      background: $grey-light;
    }
    &::after {
      content: "\2304";
      font-size: 36px;
      line-height: 0;
      font-weight: normal;
      padding-left: 15px;
      position: absolute;
      right: 5px;
      top: 12px;
    }
    &.is-loading::after {
      display: none;
    }
  }
  .export-links {
    display: none;
    position: absolute;
    z-index: 1;
    width: 180px;
    font-weight: bold;
    background: $white;
    top: 48px;
    right: 0;

    &.show {
      display: block;
    }
  }
  .export-link {
    display: block;
    margin-top: -2px;
    padding-left: 15px;
    border: solid 2px $grey-medium;
    font-weight: normal;
    color: $black;

    &:first-child {
      margin-top: -6px;
      padding-top: 5px;
    }
    &:hover {
      background-color: $grey-lighter;
    }
  }
}
.notebook-entries--select-all {
  width: 25px;
}
.notebook-entries--title {
  width: 225px;
}
.notebook-entries--task {
  width: 190px;
}
.notebook-entries--description {
  width: 380px;
}
.notebook-entries--status {
  width: 150px;
}
.notebook-entry {
  &:hover > td {
    background-color: transparent;
  }
  &.is-expanded td {
    background-color: lighten($grey-lightest, 2);
  }
}
.notebook-entry--title {
  a {
    display: block;
  }
  .play-name {
    display: block;
    font-weight: bold;
  }
  .act-scene-line {
    display: block;
    font-size: 12px;
    line-height: 12px;
  }
}
.notebook-entry--task {
  font-weight: bold;
}
.notebook-entry--description {
  font-size: 12px;

  .description-label {
    font-weight: bold;
    font-size: 14px;
  }
}
.notebook-entry--excerpt {
  .excerpt-label {
    font-weight: bold;
  }
  .lines-renderer-wrapper {
  }
}
.notebook-entry--status {
  font-weight: bold;
}
.notebook-entry--expand-collapse-toggle {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 18px;
  font-weight: bold;
}
.notebook-entry--expanded-row {
  td {
    border-top: 0px;
    padding-top: 0px;
  }
}
.notebook-entry .lines-renderer-wrapper {
  border: solid 1px $grey-light;
  border-radius: 3px;
  background: $white;
  padding: 20px 20px 20px 24px;

  .character-name,
  .stage-direction,
  .line {
    padding: 0;
  }

  .character-name {
    font-size: 16px;
  }

  .line,
  .stage-direction {
    font-size: 12px;
    line-height: 14px;
  }

  .line-number {
    right: auto;
    left: -18px;
    font-size: 10px;
    line-height: 32px;
  }
}
