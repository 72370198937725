.page-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
}
.page-title-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}
.page-title {
  @extend %page-title;
  margin: 0;
  padding-left: 15px;
  overflow: hidden;
  text-align: center;
  font-size: 28px;
  .caps {
    font-size: 32px;
  }
  @media all and (min-width: $screen-m) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media all and (min-width: $screen-l) {
    margin: 30px auto;
    font-size: 48px;
    max-width: none;
    .caps {
      font-size: 84px;
    }
  }
}

.front {
  .page-title {
    display: none;
  }
  .node-page {
    .field-name-body {
      font-size: rem(19);
      line-height: rem(31);
      p {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
      }
      .field-item {
        margin-bottom: 80px;
      }
      .field-items {
        margin-bottom: 80px;
      }
    }
  }
}
