.mm-body--open {
  .paradigm-cta {
    display: none !important;
  }
}
.block-paradigm-cta-paradigm-cta {
  display: none;
  background-color: $light-gray;
  .paradigm-cta {
    max-width: 1024px;
    margin: 0 auto;
    padding: 20px 50px 40px;
    @include breakpoint($screen-l) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .paradigm-cta-header {
    display: flex;
    justify-content: flex-end;
  }
  .paradigm-cta-close {
    width: 26px;
    height: 26px;
    background: url('../images/icon-x.svg') 50% 50% no-repeat;
    background-size: 20px;
    text-indent: -9999px;
    cursor: pointer;
  }
  .paradigm-cta-body {
    @include breakpoint($screen-m) {
      display: flex;
    }
  }
  .paradigm-cta-heading {
    @extend %h3;
    line-height: rem(40px);
    font-size: rem(40px);
    font-weight: 500;
    padding-bottom: 15px;
  }
  .paradigm-cta-col-1 {
    @include breakpoint($screen-m) {
      width: 70%;
    }
  }
  .paradigm-cta-col-2 {
    @include breakpoint($screen-m) {
      display: flex;
      align-items: center;
      width: 30%;
      justify-content: flex-end;
    }
  }
  .paradigm-cta-button {
    padding-top: 20px;
    @include breakpoint($screen-m) {
      padding-top: 0;
    }
    a {
      @include red-button;
      border-radius: 0;
      width: 190px;
    }
  }
}
