.page-classroom-rc-quiz-answers {
  .page-wrapper {
    max-width: 1600px;
    padding-left: 20px;
    padding-right: 20px;
    .outer-wrapper {
      max-width: none;
    }
  }
}
.view-classroom-rc-answers {
  .views-table {
    width: 100%;
    table-layout: fixed;
  }
  .views-field-field-first-name,
  .views-field-field-last-name {
    width: 18%;
    word-break: break-all;
  }
  tr.even,
  tr.odd,
  table tbody tr:hover > td,
  table tbody tr:hover > th {
    background-color: #eee;
  }
  td.active,
  table tbody tr:hover > td.active {
    background-color: #ddd;
  }
}
