.paragraphs-item-credits-page-actor,
.paragraphs-item-credits-page-crewmember {
  margin: 15px 20px;

  .paradigm-pseudo-title-block {
    position: relative;
    padding-bottom: 10px;
    &:after {
      content: "";
      position: absolute;
      width: 75px;
      height: 1px;
      margin-top: 5px;
      background: $grey-lightest;
    }
    img {
      position: absolute;
      top: -20px;
      left: -80px;
      width: 70px;
    }
  }
  .paradigm-pseudo-title {
    font-size: 20px;
    line-height: 23px;
    font-weight: bold;
  }
  .node-actor-crewmember h2 {
    display: none;
  }
  .paradigm-pseudo-role {
    color: $dark-gray;
    line-height: 18px;
  }
  .paradigm-pseudo-actor-crewmember-images img {
    height: 250px;
    width: auto;
  }
  .field-name-field-actor-bio,
  .field-name-field-crewmember-bio {
    padding-top: 10px;
    line-height: 22px;
  }
}
