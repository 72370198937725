.node-what-s-happening {
  .field-label {
    color: $black;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
  }
  .field-name-field-subtitle {
    font-style: italic;
    text-align: center;
    margin-bottom: 10px;
    font-size: 20px;
  }
  .field-name-field-characters .gloss-text {
    color: $red;
    &:hover {
      color: $black;
    }
  }
}
.block-paradigm-blocks-scene-summary-content {
  .what-s-happening-wrapper {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 65px;
    @include breakpoint($screen-m) {
      padding: 10px 100px;
    }
    @include breakpoint($screen-l) {
      padding-left: 120px;
      padding-right: 120px;
    }
  }
  .field-name-field-subtitle {
    margin-top: 3%;
  }
  .field-name-body {
    padding-bottom: 4%;
  }
  @include breakpoint($screen-m) {
    z-index: 500;
  }
}
.mm--main {
  .what-s-happening-wrapper {
    width: 94%;
    margin-left: auto;
    margin-right: auto;
  }
}
