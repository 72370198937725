@mixin notebook-annotation-colors-field($specifier) {
  label {
    position: relative;
    display: inline-block;
    padding-left: 24px;
    height: 25px;

    &::before {
      height: 22px;
      width: 22px;
    }
  }
  label::before {
    border-radius: 0;
    border-color: $grey-lightest;
    border-width: 2px;
  }
  input:checked + label::before {
    background-image: url('../images/check.svg');
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: center;
    border-color: $grey-dark;
  }
  input:checked + label::after {
    color: transparent;
  }
  &.#{$specifier}-yellow label::before {
    background-color: $yellow;
  }
  &.#{$specifier}-blue label::before {
    background-color: $blue-light;
  }
  &.#{$specifier}-pink label::before {
    background-color: $pink;
  }
  &.#{$specifier}-green label::before {
    background-color: $green-medium;
  }
}
.notebook--filters {
  clear: both;

  .fields {
    clear: both;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: solid 2px $grey-light;
    padding: 15px 10px;
    margin-bottom: 15px;
  }
  .field {
    margin-bottom: 15px;
  }
  label {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 5px;
    font-weight: normal;
  }
  select {
    width: 100%;
    padding: 8px 5px;
    margin: 0;
    color: $red;
    height: 49px;
    box-sizing: border-box;
    outline: none;
    @include appearance(none);
    background-image: url('../images/dropdown-arrow.png');
    background-repeat: no-repeat;
    background-position: right 5px center;
    option {
      color: $black;
      background-color: $white;
    }
  }
  select,
  .react-select,
  .react-tags {
    background-color: $light-gray;
    border: none;
    border-radius: 3px;
  }
  .react-tags {
    font-size: 16px;
    line-height: 18px;
  }
  .react-tags.is-focused,
  select:focus,
  .react-tags:focus,
  .react-select-act__control--is-focused,
  .react-select-scene__control--is-focused,
  .react-select-taskTypes__control--is-focused {
    box-shadow: rgb(153, 153, 153) 1px 1px 10px -1px;
  }
  .field-group {
    label {
      margin-bottom: 10px;
    }
    .field-group-options{
      display: flex;
    }
    .field {
      padding: 0;
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
    input[type="checkbox"] {
      display: none;
    }
  }
  .field--play_id {
    width: 32%;
    @media all and (min-width: $screen-l) {
      width: 27%;
    }
  }
  .field--act,
  .field--scene,
  .field--status {
    width: 30%;
    @media all and (min-width: $screen-l) {
      width: 13%;
    }
  }
  .field--taskTypes {
    width: 50%;
  }
  .react-select {
    line-height: 18px;
    background-color: $light-gray;
    input {
      margin-bottom: 0px;
      &:focus {
        box-shadow: none;
      }
    }
    .react-select-act__value-container,
    .react-select-scene__value-container,
    .react-select-taskTypes__value-container {
      padding: 11px 5px;
    }
    .react-select-act__control,
    .react-select-scene__control,
    .react-select-taskTypes__control {
      background-color: transparent;
      border: none;
    }
    .react-select-act__indicators,
    .react-select-scene__indicators,
    .react-select-taskTypes__indicators {
      margin-right: 3px;
    }
    .react-select-act__clear-indicator,
    .react-select-scene__clear-indicator,
    .react-select-taskTypes__clear-indicator {
      display: none;
    }
    .react-select-act__multi-value__remove,
    .react-select-scene__multi-value__remove,
    .react-select-taskTypes__multi-value__remove {
      padding: 0;
      svg {
        fill: $red;
        &:hover {
          fill: $dark-red;
        }
      }
      &:hover {
        background-color: transparent;
      }
    }
    .react-select-act__indicator-separator,
    .react-select-scene__indicator-separator,
    .react-select-taskTypes__indicator-separator {
      display: none;
    }
    .react-select-act__dropdown-indicator,
    .react-select-scene__dropdown-indicator,
    .react-select-taskTypes__dropdown-indicator {
      padding: 0px;
      svg {
        fill: $grey-darker;
      }
    }
    .react-select-act__multi-value__label,
    .react-select-scene__multi-value__label,
    .react-select-taskTypes__multi-value__label {
      padding: 1px 1px 1px 4px;
      color: $red;
      font-size: 14px;
      line-height: 17px;
    }
    .react-select-act__multi-value,
    .react-select-scene__multi-value,
    .react-select-taskTypes__multi-value {
      border: 1px solid $red;
      background-color: transparent;
    }
  }
  .field--tags {
    width: 68%;
    .react-tags input {
      background-color: transparent;
      line-height: 25px;
    }
    @media all and (min-width: $screen-l) {
      width: 30%;
    }
  }
  .field--checkbox {
    @extend %notebook-checkbox;
  }
  .field-group--annotation-type .field {
    @include notebook-annotation-colors-field('field--annotationColors');
  }
  .form-submit {
    align-self: center;
    margin: 0px 10px;
    padding: 14px 16px;
    font-size: 16px;
    width: 150px;
  }
  .form-reset {
    float: right;
    background: none;
    padding: 12px 20px;
    @extend a;
  }
}
.notebook--back-to-play {
  @include back-to-play-button;
  margin-top: -100px;
  position: relative;
  z-index: 1000;
}
