.art-type-popup-video {
  width: auto;

  &.video_performance {
    .art-icon {
      background: url('../images/icon-video-performance.png') 50% 50% no-repeat;
      background-size: 100% 100%;
    }
  }
  &.video_note {
    .art-icon {
      background: url('../images/icon-video-note.png') 50% 50% no-repeat;
      background-size: 100% 100%;
    }
  }
  &.video_song {
    background: url(/sites/all/themes/custom/neatshakes/images/icon-video-song.png) 50% 50% no-repeat;
    background-size: 100% 100%;
  }
}
