.paragraphs-item-blog-pull-quote {
  padding-left: 90px;
  background-image: url(../images/quote-left.svg);
  background-repeat: no-repeat;

  .content {
    margin-bottom: 30px;
    padding-left: 40px;
    border-left: 2px solid $red;
    color: $red;
  }

  .field-name-field-quote {
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 0.622px;
  }

  .field-name-field-attribution {
    display: flex;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.45px;
  }
}