.animate-grow {
  transform: scale(0,0);
  transition-property: transform;
  -webkit-backface-visibility: hidden;
  visibility: hidden;
}
.animate-grow.animate-show {
  transform: scale(1,1);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  visibility: visible;
}
.animate-height {
  transition-property: height;
}
.animate-grow.animate-height {
  transition-property: transform, height;
}
.animate-max-height {
  transition: max-height .5s ease-in-out;
}
.animate-slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}
.animate-slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}
@keyframes slide-in {
  100% { transform: translateX(0%); }
}
@-webkit-keyframes slide-in {
  100% { -webkit-transform: translateX(0%); }
}
@keyframes slide-out {
  0% { transform: translateX(0%); }
  100% { transform: translateX(120%); }
}
@-webkit-keyframes slide-out {
  0% { -webkit-transform: translateX(0%); }
  100% { -webkit-transform: translateX(120%); }
}
