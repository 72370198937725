.art-react {
  .art-icon {
    display: block;
    cursor: pointer;
    width: 40px;
    height: 40px;

    @include breakpoint($screen-l) {
      width: 50px;
      height: 50px;
    }
  }
  .field-name-field-popup-heading {
    @include breakpoint($screen-m) {
      margin-right: 0;
      margin-left: 0;
    }
  }
}
.group-art {
  position: absolute;
  left: 0px;
  top: -10px;
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */

  @include breakpoint($screen-l) {
    z-index: 50;
  }

  &.group-art--line-start {
    position: absolute;
    top: 0;
    right: 10px;
    left: auto;

    @include breakpoint($screen-m) {
      right: 50px;
    }

    @include breakpoint($screen-l) {
      left: 20px;
      right: auto;
    }

    @media all and (min-width: $screen-l + 181px) {
      left: 0;
    }
  }

  &.group-art--line-end {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    width: 50px;

    @include breakpoint($screen-m) {
      right: 40px;
    }

    @include breakpoint($screen-l) {
      display: flex;
      top: -10px;
      right: 10px;
      width: 180px;
      .art-top-level-component {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
  &.has-open-tooltip {
    z-index: 99;
  }
}
.group-art,
.art-aggregator {
  .art-icon,
  canvas {
    width: 40px;
    height: 40px;

    @include breakpoint($screen-l) {
      width: 50px;
      height: 50px;
    }
  }
  .group-tooltip-content {
		display: block;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 990;
    max-height: 50%;
    overflow-y: scroll;
    width: 100%;
    transform: translateY(100%);
    transition: 0.2s linear;
    background-color: $grey-lightest;
    padding: 20px;

    @include breakpoint($screen-m) {
      padding: 50px;
    }

    &.mobile-expanded {
      transform: translateY(0);
      -webkit-box-shadow: 0px 8px 30px 8px $grey-light;
         -moz-box-shadow: 0px 8px 30px 8px $grey-light;
              box-shadow: 0px 8px 30px 8px $grey-light;
    }

    .character-name {
      padding: 0;
    }

    @include breakpoint($screen-l) {
      display: none;
    }
  }
}
.art-aggregator {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 120px;

  .art-react,
  .entity {
    margin: 5px 0;
    position: relative;
  }

  position: fixed;
  top: 0;
  right: 0;
  z-index: 990;
  padding: 0 10px;
  background-color: $grey-lightest;
  transform: translateX(70px);
  transition: 0.2s linear;

  @include breakpoint($screen-m) {
    padding: 0 25px;
    transform: translateX(90px);
  }

  &.mobile-expanded {
    transform: translateX(0);
    -webkit-box-shadow: -10px 15px 17px -6px $grey-light;
       -moz-box-shadow: -10px 15px 17px -6px $grey-light;
            box-shadow: -10px 15px 17px -6px $grey-light;
  }

  canvas {
    border-radius: 40px;
  }
}

.art-aggregator-btn {
  width: 40px;
  height: 40px;
  border: 1px solid;
  border-color: $red;
  border-radius: 40px;
  color: $red;
  font-size: rem(28);
  line-height: rem(25);
  font-weight: bold;
  text-align: center;
}
.field-name-field-popup-heading {
  @include breakpoint($screen-m) {
    margin-right: 140px;
    margin-left: 140px;
  }

  @include breakpoint($screen-l) {
    margin-right: 0;
    margin-left: 0;
  }

  .popup-close-btn-wrapper {
    margin-bottom: 15px;

    @include breakpoint($screen-m) {
      position: absolute;
      right: 50px;
    }
  }
}
.fancybox-type-iframe {
  .fancybox-skin {
    @media all and (max-width: $screen-m) {
      background: transparent;
      -webkit-box-shadow: none;
         -moz-box-shadow: none;
              box-shadow: none;
    }
  }
  .fancybox-inner {
    background-color: $black;
  }
}
