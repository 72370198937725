.page-user-classrooms {
  .content {
    max-width: 1000px;
    margin: 0 auto;
  }
  .classroom-create-link a {
    @extend %button;
  }
  table tbody tr:hover>td {
    background-color: inherit;
  }
  .view-teacher-classrooms,
  .view-student-classrooms {
    margin: 40px 0px;

    .view-header h2 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 0px;
    }
    table {
      margin-top: 0px;
    }
  }
  .classroom-join-form {
    form > div {
      display: flex;
      align-items: end;
    }
    .form-item-join-code {
      width: 250px;
      margin-bottom: 0px;
      
      input {
        margin-bottom: 0px;
      }
    }
    .form-actions {
      margin: 0px 0px 2px 20px;
    }
  }
}
