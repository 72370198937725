@font-face {
  font-family: 'Ivory W00';
  src: url('../fonts/ivory-headline/ivory-headline-regular-webfont.woff2') format('woff2'),
       url('../fonts/ivory-headline/ivory-headline-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Libre Baskerville';
  src: url('../fonts/libre-baskerville/librebaskerville-regular-webfont.woff2') format('woff2'),
       url('../fonts/libre-baskerville/librebaskerville-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Libre Baskerville';
  src: url('../fonts/libre-baskerville/librebaskerville-bold-webfont.woff2') format('woff2'),
       url('../fonts/libre-baskerville/librebaskerville-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Libre Baskerville';
  src: url('../fonts/libre-baskerville/librebaskerville-italic-webfont.woff2') format('woff2'),
       url('../fonts/libre-baskerville/librebaskerville-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Droid Serif';
  src: url('../fonts/droid-serif/droidserif-webfont.woff2') format('woff2'),
       url('../fonts/droid-serif/droidserif-webfont.woff') format('woff'); 
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Droid Serif';
  src: url('../fonts/droid-serif/droidserif-bold-webfont.woff2') format('woff2'),
       url('../fonts/droid-serif/droidserif-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Playfair Display';
  src: url('../fonts/playfair-display/playfairdisplay-regular-webfont.woff2') format('woff2'),
       url('../fonts/playfair-display/playfairdisplay-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Playfair Display';
  src: url('../fonts/playfair-display/playfairdisplay-bold-webfont.woff2') format('woff2'),
       url('../fonts/playfair-display/playfairdisplay-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
