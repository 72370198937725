.logo-with-slogan {
  line-height: 1;
  text-align: center;
  margin-top: 65px;
  margin-bottom: 50px;
  font-size: rem(22);
  img {
    display: block;
    margin: 0 auto;
  }
}
