#admin-bar {
  position: fixed;
}
body > .slicknav_menu {
  position: fixed;
  z-index: 999;
  width: 100%;
}
.sticky-nav {
  background: $red;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 100vw;
  height: 72px;
  z-index: 990;
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;

  a, a:hover {
    color: $white;
  }
  .block-logo-text {
    margin: 0px 0px 0px 20px;
    @include breakpoint($screen-xl) {
      margin: 0px 0px 0px 64px;
    }
    .content {
      font-size: 1.75rem;
      em {
        font-size:  2.1875rem;
      }
      a {
        padding: 0px;
      }
    }
  }
}
.admin-bar {
  .sticky-nav {
    top: 35px;
    @include breakpoint($screen-xl) {
      top: 30px;
    }
  }
}
/* Layout for Sticky Nav */
.block-paradigm-blocks-toggle-menu,
.block-paradigm-blocks-toggle-menu-plays,
.toggle-menu-site-landing {
  display: none;
  @include breakpoint($screen-xl) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
  }
}
.block-menu-pager {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.sticky-nav {
  .block-paradigm-user-login,
  .block-paradigm-user-account {
    margin: 0px 20px 0px 0px;
    padding: 5px;
    display: flex;
    align-items: center;
    height: auto;
    width: auto;
    background-color: $red;
    border-radius: 5px;
    height: min-content;
    @include breakpoint($screen-xl) {
      padding: 0px;
      margin: 0px 64px 0px 0px;
      height: auto;
      background-color: $white;
    }
    .paradigm-user-menu-button-icon {
      display: block;
      position:static;
      width: 24px;
      height: 24px;
      background: url('../images/icon-user-white.svg') 50% 50% no-repeat;
      @include breakpoint($screen-xl) {
        background: url('../images/icon-user-red.svg') 50% 50% no-repeat;
      }
    }
  }
}
