.block-block-8 {
  max-width: 1000px;
  margin: 0 auto 60px auto;
  padding: 20px;

  h2 {
    @extend h1;
    color: $black;
    text-align: center;
  }
  p {
    @include responsive-video;
  }
}
