.page-classroom {
  .page-title {
    display: none;
  }
  .tabs {
    display: none;
  }
  .content {
    max-width: 800px;
    margin: 0px auto;
  }
  .field {
    margin-bottom: 20px;
  }
  .pseudo-field-label {
    h3 {
      font-size: 16px;
      color: black;
      font-weight: bold;
    }
  }
}
.page-classroom.page-classroom-rc-quiz-answers {
  .content {
    max-width: none;
  }
}
#paradigm-classroom-form {
  .field-name-field-classroom-graduation-year {
    legend {
      font-weight: bold;
      margin-bottom: 6px;
    }
    .date-combo {
      padding: 0px;
      background-color: transparent;
      border: none;
    }
    .date-padding {
      padding: 0px;
    }
    select {
      margin: 0px;
    }
  }
  .field-name-field-classroom-code input[disabled] {
    border: none;
    box-shadow: none;
    cursor: text;
    padding: 0px 0px 0px 12px;
  }
  .field-pseudo-field--classroom-student-list {
    margin-top: 30px;

    table {
      margin-top: 0px;
      margin-bottom: 40px;
    }
  }
  .field-name-field-rc-quiz-enabled-plays {
    .form-type-checkbox {
      @extend %notebook-checkbox;
      label {
        padding-top: 0;
        font-size: 14px;
        &::before,
        &::after {
          margin-top: 3px;
        }
      }
    }
  }
}
.entity-paradigm-classroom {
  margin: 30px 0px;

  .field-pseudo-field--classroom-title-with-edit-link {
    font-size: 24px;
    font-weight: bold;

    span {
      font-size: 14px;
    }
  }
  .field-name-field-classroom-code,
  .field-pseudo-field--classroom-student-list {
    margin-top: 15px;

    .field-label {
      margin-bottom: 0;
    }
  }
  .field-pseudo-field--classroom-student-list {

    table {
      margin-top: 0px;
    }
  }
}
.rc-quiz-classroom-toggle-quiz {
  display: block;
  position: relative;
  line-height: 23px;
  padding-left: 55px;
  .toggle-icon {
    position: absolute;
    top: 0px;
    left: 0;
  }
}
