.node-type-site-landing-page {
  .page-wrapper {
    max-width: none;
    .outer-wrapper {
      max-width: none;
    }
  }
  .page-title {
    display: none;
  }
}
.node-site-landing-page {
  .field-name-field-title {
    font-size: 40px;
    padding: 40px 0;
  }
  .button {
    border: 2px solid $red;
    padding: 10px 30px;
    text-align: center;
    border-radius: 10px;
    &:hover {
      background-color: $red;
      color: $white;
    }
  }
  .view-play-list {
    @include breakpoint($screen-m) {
      padding-top: 20px;
    }
  }
  .paragraphs-item-site-landing-page-intro {
    text-align: center;
    padding: 40px 0 30px;
    .field-name-field-title-logo {
      img {
        width: 90%;
        @include breakpoint($screen-m) {
          width: 50%;
          max-width: 900px;
        }
      }
    }
  }
}
