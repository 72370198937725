%video-banner-base {
  margin-top: 1em;
  margin-bottom: 1em;
  .content {
    @include clearfix;
    padding-top: 10px;
    border-top: 2px solid $grey-dark;
  }
  color: $red;
  font-family: $baskerville;

  &.show-bottom-border .content {
    border-bottom: 2px solid $grey-dark;
    margin-bottom: 10px;
  }

  .group-banner {
    @include display(flex);
    @include flex-direction(row);
    @include justify-content(space-between);
    @include align-items(center);
  }
  .group-image {
    width: 35%;
    @include flex-direction(column);
    @include justify-content(center)
  }
  .group-text {
    width: 60%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    @include display(flex);
    @include flex-direction(column);
    @include justify-content(center);
    font-size: rem(18px);
  }
  .field-name-field-headline,
  .field-name-field-headline-text,
  .field-name-field-headline-subtitle,
  .pseudo-video-banner-headline-link,
  .pseudo-video-link-headline-link {
    text-align: left;
  }
  .field-name-field-headline-text {
    margin-bottom: .25em;
    p {
      margin-bottom: 0;
    }
  }
}
%video-banner-image-field {
  width: 100%;
  display: block;
  height: 215px;
  img {
    display: block;
    width: auto;
    max-height: 100%;
    margin: 0 auto;
  }
}

.paragraphs-item-video-banner-intro {
  @extend %video-banner-base;
  .video-banner-intro-image-link {
    @extend %video-banner-image-field;
  }
  .video-banner-intro-description-link {
    display: block;
    &, &:hover {
      color: $black;
      text-decoration: none;
    }
  }
  .group-text {
    padding-right: 50px;
  }
}

.paragraphs-item-video-link {
  @extend %video-banner-base;
  .group-banner {
    .group-image {
      width: 11%;
    }
  }
  .content {
    border-top: none;
  }
  .group-text {
    width: 87%;
  }
}
