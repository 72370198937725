.art-type-multiple-choice {
  position: relative;
  .art-icon {
    background: url('../images/mc-quiz-icon-complete.svg') 50% 50% no-repeat;
    background-size: contain;
  }
  &.incomplete .art-icon {
    background-image: url('../images/mc-quiz-icon-incomplete.svg');
  }
  &.disabled .art-icon {
    background-image: url('../images/mc-quiz-icon-disabled.svg');
  }
}
.multiple-choice-question-header {
  text-align: center;
  margin-bottom: 9px;
}
.multiple-choice-subtitle-wrapper {
  position: relative;
  .slider-pager {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.multiple-choice-question-number {
  margin-bottom: 15px;
  text-align: center;
  font-size: 18px;
  font-style: italic;
}
.multiple-choice-question-wrapper {
  @include artool-text;
}
.multiple-choice-question {
  p {
    margin-bottom: 10px;
  }
}
.multiple-choice-option {
  cursor: pointer;
  border-radius: 7px;
  margin-bottom: 10px;
  border: solid 1px $option-border-color;
  padding: 10px;
  background-color: $white;
  &.correct {
    border-color: #0C8528;
    background: $green-light url('../images/icon-checkmark.svg') calc(100% - 10px) 13px no-repeat;
    background-size: 15px 15px;
  }

  &.incorrect {
    border-color: #DA0A0A;
    background: $red-light url('../images/icon-x.svg') calc(100% - 10px) 12px no-repeat;
    background-size: 12px 12px;
  }

  .is-complete & {
    cursor: default;
  }
}
.multiple-choice-option-label-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.multiple-choice-option-label-enumerator {
  width: 20px;
}
.multiple-choice-option-label {
  width: calc(100% - 50px);
  flex: 0 0 auto;
  p {
    margin: 0;
  }
}
.multiple-choice-option-description {
  margin-top: 9px;
  padding-top: 10px;
  font-size: 12px;
  line-height: 16px;
  border-top: solid 1px $grey-lighter;
  p {
    margin: 0;
  }
}
.multiple-choice-option-feedback {
  color: $red;
  &.is-correct {
    color: $green;
  }
}
