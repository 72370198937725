$folio-pager-height: 53px;

.folio-pager-wrapper {
  z-index: 250;
  left: 0;
  bottom: 0;
  margin-bottom: 5px;
  background: $grey-lightest;
  width: 100%;
  @include breakpoint($screen-m) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &.is-fixed {
    position: fixed;
    margin-bottom: 0px;
  }
}
.folio-pager {
  display: flex;
  align-items: center;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  line-height: 2.125;
  a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .folio-pager-link--prev,
  .folio-pager-link--next {
    flex: 1 1 0px;
    width: 53px;
    height: $folio-pager-height;
    text-indent: -999em;

    &.spacer {
      background: none;
    }
  }
  .folio-pager-link--prev {
    margin-right: auto;
    background: url('../images/left-arrow-red.png') 50% 50% no-repeat;
    background-size: 20px auto;
    @include breakpoint($screen-m) {
      width: auto;
      background-position-x: 0;
      padding-left: 30px;
      text-indent: 0;
    }
  }
  .folio-pager-link--next {
    margin-left: auto;
    background: url('../images/right-arrow-red.png') 50% 50% no-repeat;
    background-size: 20px auto;
    @include breakpoint($screen-m) {
      width: auto;
      text-indent: 0;
      background-position: 100% 50%;
      padding-right: 30px;
      text-align: right;
    }
  }
}
.block-paradigm-folio-pager {
  min-height: $folio-pager-height;
}

