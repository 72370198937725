#slideout-content-wrapper.is-expanded {
  .slideout-content {
    transform: translateY(0);
    box-shadow: 0px 8px 30px 8px $grey-light;
  }
  & div[id^='modern-english-'] {
    max-height: 35%;
  }
}
.slideout-content {
  display: block;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 990;
  max-height: 50%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  transform: translateY(100%);
  transition: transform 0.2s linear;
  background-color: $grey-lightest;
  padding: 20px;

  .character-name {
    padding: 0;
  }
}
.slideout-content-inner {
  @include artool-text;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0px 30px;

  @include breakpoint($screen-m) {
    padding-left: 50px;
    padding-right: 130px;
  }
  @include breakpoint($screen-l) {
    padding-left: 120px;
    padding-right: 350px;
  }
  .popup-note-content,
  .modern-english-content {
    font-size: 16px;
    line-height: 2.125;
  }
  .field-name-field-popup-heading {
    font-size: 18px;
    margin-bottom: 9px;
  }
  .question-header-question {
    padding-bottom: 10px;
  }
  .line-placeholder {
    line-height: 37px;
  }
  .react-tags {
    margin-bottom: 15px;
  }
  .messages {
    text-align: center;
  }
  h3.character-name {
    padding: 0;
    font-size: 18px;
  }
  p, ul, ol, table {
    margin-top: .5em;
    margin-bottom: .5em;
    font-size: 14px;
    line-height: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  p {
    margin-top: 0;
  }
  ul {
    list-style-type: disc;
    padding-left: 17px;
  }
  ol {
    list-style-type: decimal;
    padding-left: 17px;
  }
}
.slideout-controls {
  position: relative;
  top: 0px;
  right: auto;
  text-align: right;
  float: right;
  display: flex;

  .slideout-translate-btn-wrapper,
  .slideout-close-btn-wrapper {
    button {
      border-radius: 5px;
      padding: 5px;
      font-size: 14px;
    }
  }
  .slideout-translate-btn-wrapper{
    margin-right: 5px;
  }
  .slideout-close-btn-wrapper {
    button {
      background-color: $white;
      color: $red;
      border: 1px solid $red;
    }
  }

  .slideout-btn-small-screen {
    padding: 0 2px;
    @include breakpoint($screen-l) {
      display: none;
    }
  }
  .slideout-btn-text {
    display: none;
    padding: 5px 15px;

    @include breakpoint($screen-l) {
      display: block;
    }
  }
}
