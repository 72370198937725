.paragraphs-items-field-interactions {
  position: relative; /* for child pseudo-element */
  z-index: 0;
  margin: 0 -600rem;
  /* add back negative margin value */
  padding: 2.25rem 600rem;
  background: $grey-lighter;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 598.5rem;
    right: 598.5rem;
    background: $grey-lighter;
  }


  .field-name-field-interactions {
    @media all and (max-width: $screen-m) {
      padding: 0 0 15px;
    }
  }

  @media all and (max-width: $screen-m) {
    padding: 1.25rem 600rem 0;
  }
}

.paragraphs-item-interaction-type {
  .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;

    .field-name-field-image {
      width: 75px;
      margin: 5px;
      img {
        width: 80%;
      }
    }
    .field-name-field-description {
      width: 45%;
      font-size: rem(16px);
      line-height: rem(22px);

      @media all and (max-width: $screen-m) {
        width: 70%;
      }
    }
  }
}
