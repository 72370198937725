.page-search {
  .page-title {
    @media all and (max-width: $screen-m) {
      margin: 30px;
    }
    @media all and (max-width: $screen-s) {
      text-align: left;
      margin: 10px;
    }
  }
  .pager--infinite-scroll-auto {
    .views_infinite_scroll-ajax-loader {
      margin: auto;
      width: 43px;
      img {
        width: 43px;
        height: auto;
      }
    }
  }
}
