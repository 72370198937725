.view-dramatis-personae {
  padding-bottom: 50px;

  .views-row {
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid $medium-gray;

    &:last-child {
      border: none;
    }
  }

  .views-field-nothing {
    > .field-content {
      display: flex;
      flex-direction: column;
      @include breakpoint($screen-m) {
        flex-direction: row;
      }
    }
  }

  .dramatis-personae-right {
    flex: 1 1 0%;
    padding-left: 0px;

    h3 {
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: center;
      @include breakpoint($screen-m) {
        text-align: left;
      }
    }

    @include breakpoint($screen-m) {
      padding-left: 40px;
    }
  }

  .dramatis-personae-left {
    margin: 0 auto;
    padding: 25px;
    width: 235px;
    height: 300px;
    background-image: url('../images/character-frame.svg');
    background-position: 0px 2px;
    background-repeat: no-repeat;
    background-size: 100%;

    img {
      display: block;
    }

    @include breakpoint($screen-m) {
      margin: 0;
    }
  }
}
