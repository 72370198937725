.node-teacher-testimonial {
  &:before,
  &:after {
    display: block;
    margin: 30px;
    text-align: center;
    @include breakpoint($screen-m) {
      margin: 0;
      text-align: left;
    }
  }
  &:before {
    content: url('../images/quote-left.svg');
  }
  &:after {
    content: url('../images/quote-right.svg');
    @include breakpoint($screen-m) {
      float: right;
    }
  }
  p {
    line-height: 1.6;
    margin: 0 60px;
    text-align: center;
  }
}

.field-name-field-attribution {
  &:before {
    content: '\2014';
  }
}
