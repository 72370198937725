.block-paradigm-blocks-header-search {
  position: relative;
  display: none;
  @include breakpoint($screen-s) {
    display: block;
  }
  .header-search-button {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    @include breakpoint($screen-xl) {
      margin-top: 0px;
      margin-right: 32px;
    }
    .text-search {
     display: none;
     @include breakpoint($screen-xl) {
      display: inline;
     }
    }
  }
  .icon-search {
    display: block;
    width: 30px;
    height: 45px;
    background-color: initial;
    background-image: url('../images/spyglass-white.svg');
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: center;
    @include breakpoint($screen-xl) {
      margin-right: 8px;
    }
  }
  .icon-close {
    display: none;
    background-image: url('../images/icon-x-white.svg');
    background-size: 18px;
  }
  form {
    padding: 0 20px 15px 20px;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: $red;
    display: none;
  }
  .is-open {
    .icon-search {
      display: none;
    }
    .icon-close,
    form {
      display: block;
    }
  }
  .form-type-textfield {
    position: relative;
    margin: 0.5em 0;
    label {
      display: none;
    }
    input.form-text {
      display: block;
      width: 250px;
      background-color: initial;
      border: none;
      border-radius: 0;
      box-shadow: none;
      color: $white;
      padding: 10px 10px 0px 5px;
      border-bottom: $white 2px solid;
    }
  }
  input.form-submit {
    width: 45px;
    background-color: initial;
    background-image: url('../images/spyglass.png');
    background-repeat: no-repeat;
    background-size: 23px;
    background-position: center;
  }
  form#paradigm-blocks-search-form > div {
    display: flex;
    height: 50px;
  }
}
