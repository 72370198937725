.art-type-written-quiz {
  position: relative;
  width: auto;
  .art-icon {
    background: url('../images/w-quiz-icon-complete.svg') 50% 50% no-repeat;
    background-size: contain;
  }
  &.incomplete .art-icon {
    background-image: url('../images/w-quiz-icon-incomplete.svg');
  }
  &.disabled .art-icon {
    background-image: url('../images/w-quiz-icon-disabled.svg');
  }
}
.written-quiz-wrapper {
  @include artool-text;

  .written-quiz-question p {
    margin-bottom: 10px;
  }
  .written-quiz-answer {
    border-color: $grey-light;
    height: 120px;
    margin-bottom: 10px;
  }
  .react-tags {
    margin-bottom: 20px;
  }
  .form-submit {
    @extend %small-button;
  }
}
