.paragraphs-item-two-column-heading-and-text {
  padding-left: 25px;
  padding-right: 25px;
  margin: 50px auto 50px;
  text-align: left;
  @include breakpoint($screen-xl) {
    margin: 112px auto 80px;
    max-width: 1362px;
  }
  .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @include breakpoint($screen-xl) {
      flex-direction: row;
    }
  }
  .field-name-field-heading {
    padding-right: 40px;
    padding-bottom: 40px;
    color: $black;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    width: 100%;
    @include breakpoint($screen-m) {
      font-size: 48px;
    }
    @include breakpoint($screen-xl) {
      padding-bottom: 0px;
    }
  }
  .field-name-field-body {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    width: 100%;
  }
}
