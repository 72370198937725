.paragraphs-item-lines {
  margin-bottom: 30px;

  > .content {
    // This styling is to fix a problem caused by an issue where the content
    // will be narrower than it should be at smaller screen sizes. That issue
    // is caused by the styling for .gloss-def which includes left/right
    // positioning to allow for a wide gloss definition to appear without
    // wrapping when the glossed word is narrower than the definition.
    overflow: hidden;
    @include breakpoint($screen-l) {
      overflow: visible;
    }
  }
}
