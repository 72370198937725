.paragraphs-item-faq {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid $black;
  a {
    color: $dark-red;
  }
  .field-label {
    color: $red;
  }
  .field-name-field-faq-question {
    padding-right: 40px;
    cursor: pointer;
    &:hover {
      color: $dark-red;
    }
    &::after {
       content: url('../images/icon-plus.svg');
       position: absolute;
       right: 5px;
       top: 2px;
    }
    &.is-toggled {
      color: $dark-red;
      &::after {
        content: url('../images/icon-minus.svg');
        top: -4px;
      }
    }
  }
  .field-name-field-faq-answer {
    display: none;
    padding-top: 30px;
  }
}
