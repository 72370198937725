.block-logo-text,
.block-play-logo-text {
  opacity: 1;
  flex: 1;

  .content {
    font-size: rem(15);
    color: $white;
    letter-spacing: 1px;
    font-family: $playfair-display;
    font-weight: bold;
    line-height: rem(37);

    @include breakpoint($screen-s) {
      font-size: rem(24);
    }

    strong {
      color: $black;
    }
    em {
      font-family: $ivory-headline;
      font-weight: normal;
      font-size: rem(24);
      font-style: normal;

      @include breakpoint($screen-s) {
        font-size: rem(30);
      }
    }
    p {
      margin: 0;
    }
    a {
      display: block;
      padding: 5px 0px 5px 20px;
    }
  }
}
.myshakespeare-logo-text {
  @include logo-text(25px, 35px);
}
.block-paradigm-blocks-myshakespeare-logo-text {
  text-align: center;
  margin-left: 0;

  @include breakpoint($screen-m) {
    text-align: left;
    margin-left: 20px;
  }

  @include breakpoint($screen-l) {
    text-align: center;
    margin-left: 0;
  }
}
.block-play-logo-text {
  display: none;
  text-align: center;
  margin-top: 4px;

  @include breakpoint(1500px) {
    position: absolute;
    display: block;
    left: 50%;
    transform: translate(-50%);
    margin: 4px auto;
  }
}
