.block-blockpop-popup {
  display: none;

  h2 {
    margin-bottom: 20px;
    font-size: 34px;
    line-height: 50px;
    letter-spacing: 0.23px;
    text-align: center;
    color: $red;
  }
  .paradigm-user-dialog-register-link {
    display: inline-block;
    margin-bottom: 1rem;
  }
}
