.paradigm-user-login-title,
.paradigm-user-register-title {
  color: $black;
  font-weight: bold;

  #user-login &,
  #user-register-form &,
  .paradigm-register-form-wrapper &,
  .paradigm-user-form-wrapper & {
    margin-top: 20px;
  }
}
#edit-submit-google {
  width: 191px;
  height: 46px;
  margin-left: 20px;
  vertical-align: top;
  background-size: 100%;
  background-color: transparent;
  border-radius: 0;
}
.button-login-clever,
.button-login-google {
  background-color: transparent;
  border: 1px solid $red;
  padding-left: 40px;
  display: block;
  font-size: 12px;
  line-height: 15px;
  color: $red;
  p {
    font-size: 1.5em;
    border-left: 1px solid $red;
    padding-left: 10px;
    margin: 0;
    word-wrap: break-word;
    white-space: normal;
  }
  &:hover {
    background-color: $red;
    color: $white;
  }
}
.sso-logins-wrapper {
  display: flex;
  padding-left: 1px;
  padding-right: 1px;
  max-width: 350px;
  margin-bottom: 1.5em;
  .button {
    width: 50%;
    color: $white;
    display: block;
    margin-top: 20px;
    text-align: center;
  }
  .button:first-child {
    margin-left: 0;
    margin-right: 15px;
  }
  .button-login-clever,
  .button-login-google {
    color: $red;
    &:hover {
      color: $red;
    }
  }
  .button-login-clever {
    background: $white url('../images/logo-clever.png') 6px 50% no-repeat;
    background-size: 26px 26px;
  }
  .button-login-google {
    background: $white url('../images/logo-google.png') 6px 50% no-repeat;
    background-size: 26px 26px;
  }
}
.sso-logins-or {
  font-size: 1em;
  font-weight: bold;
}
.form-item-pass {
  .form-text {
    margin-bottom: 0px;
  }
  .description {
    font-size: 11px;
  }
}
.paradigm-register-form-wrapper {
  .form-item-mail {
    label {
      display: none;
    }
    .description {
      font-size: 10px;
      line-height: 15px;
    }
  }
}
