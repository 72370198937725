.home-page-blog,
.block-block-13,
.whats-next-blog {
  .content {
    max-width: 1200px;
    text-align: left;
    margin: 40px auto;
    h1, h2 {
      margin-bottom: 20px;
      font-size: 28px;
      line-height: 75px;
      text-align: left;
      .caps {
        font-size: 54px;
      }
    }
    h1 {
      color: $black;
    }
    h2 {
      color: $red;
    }
    .button {
      border-radius: 3px;
    }
    .flex-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
    }
    .flex-column {
      flex: 1 0 50%;
      padding: 20px;
    }
  }
}
