.block-paradigm-export-to-drive-google-integration-buttons {
  padding: 30px 0;
  border-top: solid 2px $grey-light;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media all and (max-width: $screen-m) {
      flex-direction: column;
      align-items: center;
    }
  }
}

.share-to-classroom-button,
.save-to-drive {
  margin: 10px;
}

.share-to-classroom-button {
  border: 2px solid $grey-lighter;
  width: 300px;
  height: 48px;
  display: flex;
  align-items: center;
  background-color: $grey-even-more-lightest;
  padding: 0 15px;
  font-family: 'Roboto';
  font-weight: bold;

  .share-to-classroom-icon-wrapper {
    height: 32px;
    margin-right: 15px;
  }
}

