.block-paradigm-blocks-folio-quick-nav {
  display: none;

  @include breakpoint($screen-m) {
    display: block;
  }
}

ul.folio-quick-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: $red;

  .video-link:hover {
    color: $dark-red;
    cursor: pointer;
  }
}
