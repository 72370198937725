#paradigm-profile {
  min-width: 100%;
  padding: 20px 20px 250px 20px;

  @include breakpoint($screen-m) {
    min-width: 700px;
    padding: 40px 40px 250px 40px;
  }
  h4 {
    max-width: 620px;
    background-color: $yellow-highlight;
    padding: 20px;
    font-weight: bold;
  }
  .messages--error {
    @include flash($error-color);
    font-size: 14px;

    div {
      padding: 10px 0px;
    }
  }
  label {
    margin-bottom: 0;

    span {
      color: $red;
      padding-left: 2px;
    }
  }
  .field {
    margin-bottom: 20px;
  }
  .field--text {
    margin-right: 4px;
  }
  input {
    margin: 0;
  }
  select {
    margin: 0;
    width: 100%;
    border: solid 1px $grey-lighter;
    border-radius: 3px;
    padding: 12px;
    line-height: 20px;
    height: 46px;
    background-color: $white;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
  }
  .select--wrapper {
    position: relative;
    width: 100%;

    &:after {
      display: block;
      content: '\027A4';
      color: $black;
      transform: rotate(90deg);
      position: absolute;
      bottom: 1px;
      right: 3px;
      width: 46px;
      background-color: $grey-lighter;
      border-radius: 3px 3px 0px 0px;
      padding: 12px;
      line-height: 20px;
      font-size: 24px;
      pointer-events: none;
      cursor: pointer;
    }
    &.is-empty {
      color: $grey-dark;
    }
    option {
      color: $black;
    }
  }
  .chosen-container {
    background-image: url(../images/chevron-down.png);
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 95% 18px;
    background-color: $white;
  }
  .chosen-single {
    background: transparent;
    font-size: 16px;
    padding: 12px;
    height: 46px;
    border-radius: 3px;
    border-color: $grey-lighter;

    b {
      display: none;
    }
  }
  .chosen-drop {
    border-color: $grey-lighter;
  }
  .field--description {
    font-size: 12px;
  }
  .field--error-message {
    font-size: 14px;
    color: $red;
  }
  .field--role .select--wrapper,
  .field--field_country .select--wrapper {
    // The first option acts as a placeholder and should not be selectable.
    option:first-child {
      display: none;
    }
  }
  .field--field_graduation_year {
    .select--wrapper:after {
      right: 0px;
    }
  }
  .form-submit {
    margin-top: 20px;
  }
  .field-mailchimp-lists {
    .field {
      display: flex;
      input {
        margin-right: 10px;
      }
    }
  }
}
.page-user-profile #paradigm-profile {
  .profile-form {
    @include breakpoint($screen-m) {
      width: 520px;
    }
  }
}
#user-profile-form {
  .form-item-pass {
    .form-text {
      margin-bottom: 15px;
    }
    .description {
      display: none;
    }
  }
  div.password-confirm {
    display: none;
  }
}
