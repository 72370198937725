.views-js-controls-link--expand {
  .label--collapse {
    display: none;
  }
  &.is-toggled {
    .label--expand {
      display: none;
    }
    .label--collapse {
      display: inline;
    }
  }
}
