body {
  @include font-feature-settings("kern", "liga", "pnum");
  -webkit-font-smoothing: antialiased;
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: $base-font-size;
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
  font-weight: normal;
}
h1,
%h1 {
  font-size: rem(48px);
  line-height: rem(64px);
  color: $red;
}
%page-title {
  @extend %h1;
  line-height: rem(84px);
  padding-top: 10px;
  .caps {
    font-family: $ivory-headline;
    font-size: rem(84px);
  }
}
.caps {
  font-family: $ivory-headline;
}
h3,
%h3 {
  font-size: rem(20px);
  color: $red;
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin: 0 0 $small-spacing;
}

a {
  color: $action-color;
  text-decoration: none;
  transition: color 0.1s linear;

  &:active,
  &:focus,
  &:hover {
    color: darken($action-color, 15%);
  }

  &:active,
  &:focus {
    outline: none;
  }
}

hr {
  border-bottom: $base-border;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: $base-spacing 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
  height: auto;
}
.font-ivory-headline {
   font-family: $ivory-headline;
}
