.has-sticky-nav,
.has-quick-nav {
  .node-folio {
    .toggle-link {
      margin-top: 70px;

      @media all and (max-width: $screen-l) {
        margin-top: 50px;
        margin-bottom: 20px;
      }
    }
  }
}
.toggle-link-qs {
  .toggle-text {
    display: inline-block;
    line-height: 1.3em;
    vertical-align: middle;
    text-align: right;
  }
  .toggle-image {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    width: 52px;
    height: 52px;
    background: #a82c29;
    vertical-align: middle;
    img {
      width: 27px;
      position: absolute;
      margin: auto;
      top: 0;
      left: 5px;
      right: 0;
      bottom: 0;
    }
  }
  &:hover .toggle-image {
    background: $dark-red;
  }
}

.page-wrapper {
  .toggle-link-qs {
    display: block;
    .toggle-text {
      margin-right: 10px;
    }
  }
}

.block-paradigm-qs-ft-toggle-menu-buttons,
.block-paradigm-blocks-back-to-play-for-search-page {
  @include clearfix;
}
.block-paradigm-qs-ft-toggle-menu-buttons {
  @include clearfix;
  .content {
    a {
      background-color: $white;
      border-radius: 5px;
      padding: 3px 12px;
      margin: 5px;
      font-size: rem(14);

      @include breakpoint($screen-s) {
        margin: 0 15px 0 0;
        font-size: rem(16);
      }

      &.btn-main-menu-search {
        @include breakpoint($screen-m) {
          display: none;
        }
      }
    }
    a, a:visited {
       color: $red;
    }
    a:hover {
      opacity: 1;
      color: $black;
    }
  }
  /*override main qs toggle link classes*/
  .toggle-link .toggle-text {
     line-height: inherit;
     display: inline;
     vertical-align: baseline;
     padding-right: 0;
  }
}

.node-quick-study-page {
  .toggle-link {
    position: fixed;
    right: 30px;
    top: 125px;
    margin-bottom: 20px;

    @media all and (max-width: $screen-l) {
      position: relative;
      right: initial;
      top: initial;
      display: block;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      .toggle-image {
        display: none;
      }
    }
  }
}
.block-paradigm-qs-ft-toggle-back-to-full-text {
  display: block;
  position: relative;
  top: auto;
  width: 100%;
  height: 52px;

  @include breakpoint($screen-l) {
    height: 0;
  }

  a {
    margin-right: 0;
    padding-right: 0;
  }
  .toggle-link {
    margin-top: 0;
    padding-top: 0;
    position: absolute;
    right: 30px;
    top: auto;
    .toggle-text {
      width: 70px;
      display: inline-block;
      line-height: 1em;
      vertical-align: middle;
    }
  }
}

.block-paradigm-qs-ft-toggle-menu-buttons {
  display: none;
  margin-right: 33px;
  margin-left: 33px;
  @include breakpoint($screen-m) {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 0;
    margin-left: 0;
  }
}
.block-paradigm-blocks-back-to-play-for-search-page {
  .content {
    width: 100%;
    text-align: center;
    @include breakpoint($screen-l) {
      width: auto;
      text-align: left;
      float: right;
    }
  }
}
.block-paradigm-blocks-back-to-play-for-search-page,
.node-quick-study-page {
  .toggle-link {
    display: flex;
    background-color: $red;
    color: $white;
    border: 1px solid $white;
    border-radius: 7px;
    padding: 15px;
    width: 200px;
    font-size: 1.2rem;
    line-height: 1rem;
    margin-left: auto;
    margin-right: auto;
    @include breakpoint($screen-l) {
      background-color: transparent;
      color: $red;
      border: none;
    }
  }
  .toggle-text {
    @include breakpoint($screen-l) {
      width: 80px;
    }
  }
  .toggle-image {
    display: none;
    @include breakpoint($screen-l) {
      display: block;
    }
  }
}
