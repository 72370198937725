.node-type-folio,
.node-type-quick-study-page {
  .main-menu {
    overflow-y: scroll;
    max-height: 400px;

    @include breakpoint($screen-m) {
      overflow-y: visible;
      max-height: none;
    }
  }
}

.main-menu {
  width: 100%;
  position: fixed;
  z-index: 980;
  background: $red;
  top: 72px;
  padding-top: 1px;
  padding-bottom: 1px;
  border-bottom: 20px solid $red;
  border-top: 1px solid $black;
  transform: translateY(-100%);
  color: $white;
  @include transition(all 0.3s ease-in-out);
  line-height: 40px;
  @include breakpoint($screen-m) {
    border-bottom: none;
  }

  .block {
    opacity: 0;
    transition: opacity 0.3s step-end;
  }

  &.is-toggled {
    transform: translateY(0);

    .block {
      opacity: 1;
      transition: opacity 0.3s step-start;
    }
  }
  a {
    display: block;
  }
  a, a:visited, a:hover {
    color: $white;
  }
  a:hover {
    opacity: .5;
  }
  .block {
    margin-top: 20px;
    margin-bottom: 20px;
    @include breakpoint($screen-s) {
      margin-left: 35px;
      margin-right: 35px;
    }
  }
  .block-menu-menu-plays {
    display: none;
    .content {
      > .menu {
        @include breakpoint($screen-m) {
          justify-content: flex-end;
        }

        > li {
          width: auto;

          @include breakpoint($screen-m) {
            text-align: center;
            flex: 1 0;
          }

          @include breakpoint($screen-l) {
            padding-left: 70px;
          }
        }
      }
    }
  }
}
.main-menu .block-menu-block,
.block-menu-menu-site-landing {
  box-sizing: border-box;
  li.leaf,
  li.expanded {
    list-style-type: none;
    list-style-image: none;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
  .content {
    > .menu {
      display: flex;
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;


      @include breakpoint($screen-m) {
        margin-left: 30px;
        margin-right: 15px;
        flex-direction: row;
        flex-wrap: wrap;
      }

      @include breakpoint($screen-l) {
        margin: 0;
        justify-content: flex-end;
      }

      > li {
        box-sizing: border-box;
        display: block;
        text-align: center;

        @include breakpoint($screen-m) {
          padding-left: 10px;
          padding-right: 10px;
          text-align: left;
          flex: 1 0 33.3%;
        }

        @include breakpoint($screen-l) {
          padding: 0 15px 0 0;
          flex: 1 0;
          width: 20%;
          max-width: 200px;
          text-align: center;
          &:last-child {
            padding-right: 0;
          }
        }

        &:nth-child(1n+4) {
          @include breakpoint($screen-m) {
            padding-top: 15px;
          }

          @include breakpoint($screen-l) {
            padding-top: 0;
          }
        }

        &.mobile-collapsed-init {
          > ul {
            display: none;

            @include breakpoint($screen-m) {
              display: block;
            }
          }
        }
      }
    }
  }

  a {
    padding-top: 5px;
    padding-bottom: 10px;
    font-size: rem(18);
    line-height: rem(30);
  }

  .menu-tree li a.active {
    color: $white;
  }
  .nolink {
    display: block;
    width: 100%;
    border-bottom: 1px solid $white;
    text-align: left;

    @include breakpoint($screen-m) {
      font-size: rem(21);
    }

    @include breakpoint($screen-l) {
      text-align: center;
    }
  }

  .fa {
    float: right;
    font-size: rem(12);
    line-height: rem(40);
  }

  .fa-chevron-up {
    display: inline;

    @include breakpoint($screen-m) {
      display: none;
    }
  }

  .fa-chevron-down {
    display: none;
  }

  .mobile-collapsed {
    .fa-chevron-up {
      display: none;
    }

    .fa-chevron-down {
      display: inline;

      @include breakpoint($screen-m) {
        display: none;
      }
    }

    .menu {
      @include breakpoint($screen-m) {
        display: block !important;
      }
    }
  }
}

.block-menu-menu-site-landing {
  .content {
    display: flex;
    align-items: center;
    > .menu {
      margin-right: 32px;
      display: flex;
      align-items: baseline;
      gap: 32px;
      > li {
        @include breakpoint($screen-l) {
          padding: 0;
          flex: 1 0;
          width: 50%;
          text-align: center;
          display: flex;
          align-items: center;
        }
        a, .nolink {
          padding: 0px;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
        }
        .nolink {
          color: $white;
          border: none;
          cursor: pointer;
          display: inline-flex;
          .chevron-down {
            margin-left: 4px;
            background-color: initial;
            background-image: url('../images/chevron-down.svg');
            background-repeat: no-repeat;
            background-size: 24px;
            background-position: center;
            height: 24px;
            width: 24px;
          }
          .chevron-up {
            margin-left: 4px;
            background-color: initial;
            background-image: url('../images/chevron-up.svg');
            background-repeat: no-repeat;
            background-size: 24px;
            background-position: center;
            height: 24px;
            width: 24px;
          }
        }
      }
    }
  }
}

.main-menu {
 .content {
  .btn-main-menu-close {
    display: flex;
    align-items: center;
    padding: 2px 30px;
    width: 153px;
    height: 44px;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin: 0px;
    }
  }
}
