// This file provides styling of various fields that are shared by index pages,
// which are the following content types: Video Link Paragraph Index Item, Full Scene Audio, Full Scene Modern English, Popup Note Index Item, Video Index Item.


.node-type-full-scene-audio .node-full-scene-audio,
.node-type-full-scene-modern-english .node-full-scene-modern-english,
.node-type-video-link-paragraphs-index-item .node-video-link-paragraphs-index-item,
.node-type-video-index-item .node-video-index-item {
  width: 75%;
  margin: 30px auto 0;
  @media all and (max-width: $screen-l) {
    margin: 0 auto;
  }
  @media all and (max-width: $screen-m) {
    width: 90%;
  }
}

.node-type-full-scene-audio .field-name-field-play-term,
.node-type-full-scene-modern-english .field-name-field-play-term a,
.node-type-video-link-paragraphs-index-item .field-name-field-headline,
.node-type-video-index-item .field-name-field-headline,
.node-type-popup-note-index-item .field-name-field-index-title {
  text-align: center;
  color: $black;
  font-size: 2em;
  line-height: 1.2em;
  padding-top: 30px;
  max-width: 690px;
  margin: 0 auto;
  display: block;

  @media all and (max-width: $screen-l) {
    max-width: 645px;
  }
}

.node-type-full-scene-audio .node-full-scene-audio .group-act-and-scene,
.node-type-full-scene-modern-english .field-name-field-play-term:after,
.node-type-video-index-item .field-name-field-index-search-subtitle,
.node-type-video-link-paragraphs-index-item .field-name-field-headline:after,
.node-type-popup-note-index-item .field-name-field-index-subtitle {
  text-align: center;
  display: block;
  color: $grey-medium-darker;
  font-size: 1.75em;
  line-height: 1.5em;
}

.node-type-video-link-paragraphs-index-item,
.node-type-full-scene-audio,
.node-type-popup-note-index-item,
.node-video-index-item,
.node-full-scene-modern-english {

  .group-node-header {
    margin-bottom: 35px;

    @media all and (max-width: $screen-l) {
      margin-bottom: 20px;
    }
  }

  .field-name-field-quick-study,
  .field-name-field-index-introduction {
    background-repeat: no-repeat;
    background-position: 0 center;
    background-size: 60px;
    padding-left: 95px;
    padding-right: 95px;
    min-height: 100px;
    max-width: 90%;
    line-height: 1.4em;

    @media all and (max-width: $screen-l) {
      background-position: left top;
      padding-right: 0;
      max-width: 100%;
      margin-bottom: 30px;
    }
    @media all and (max-width: $screen-m) {
      padding: 0;
    }

    p {
      margin: 0;
    }
  }

  .field-name-field-index-introduction {
    text-align: center;

    @media all and (max-width: $screen-l) {
      padding: 0;
      text-align: left;
      min-height: 75px;
      max-width: 100%;
      margin-bottom: 10px;
    }
  }

  .group-icon-and-intro {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;

    .field-name-field-icon img,
    .field-name-field-video-icon img {
      max-width: 60px;
      margin-right: 22px;

      @media all and (max-width: $screen-m) {
        float: left;
        margin-right: 10px;
      }
    }

    .field-name-field-index-introduction {
      @media all and (max-width: $screen-m) {
        display: block;
      }
    }

    @media all and (max-width: $screen-l) {
      margin-bottom: 10px;
    }

    @media all and (max-width: $screen-m) {
      display: initial;
    }
  }

  .field-name-field-index-reference-notes {
    margin-bottom: 40px;
    p {
      line-height: 1.75em;
      margin-bottom: 0px;
    }
    ul, ol {
      list-style-type: inherit;
    }
  }

  h3.field-label {
    color: $black;
    font-size: 1.7em;
    font-weight: bold;
    line-height: 2em;
    display: block;
  }

  .character-name,
  .line {
    padding-left: 0px;
  }

}

.node-video-index-item,
.node-type-video-link-paragraphs-index-item {
  .field-name-field-index-transcripts {
    ul, ol {
      list-style-type: inherit;
    }
  }
}


.node-type-full-scene-audio,
.node-type-popup-note-index-item,
.node-video-index-item,
.node-type-video-link-paragraphs-index-item {
  .group-act-and-scene,
  .field-name-field-index-highlighted-lineref {
    color: $grey-medium-darker;
  }

  .group-act-and-scene {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    line-height: 1.2em;
  }

  .field-name-field-index-highlighted-lineref {
    text-align: center;
    line-height: 2em;
  }
}

.node-type-full-scene-audio,
.node-video-index-item,
.node-type-video-link-paragraphs-index-item {
  .field-name-field-video,
  .field-name-field-index-transcripts,
  .lines-renderer-wrapper {
    margin-bottom: 50px;
  }
}

.field-name-field-index-introduction {
  display: flex;
  align-items: center;
}
