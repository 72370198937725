.block-paradigm-blocks-social-menu {
  @include clearfix;
  .content {
    margin-top: 15px;
    font-size: rem(30px);
    line-height: rem(30px);
    .paradigm-social-menu {

      @include breakpoint($screen-m) {
        display: flex;
        text-align: end;
      }
    }
  }
  a {
    color: $black;
  }
  a:hover {
    color: #d5d8da;
  }
  .item-list {
    ul {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0;

      li {
        margin-left: 12px;
        width: 30px;
      }
      li:first-child {
        margin-left: 0;
      }
    }
    a {
      display: block;
    }
  }
}
