.block-paradigm-user,
.block-paradigm-user-login {
  position: static;
  @include breakpoint($screen-m) {
    position: relative;
  }
  @include breakpoint(990px) {
    margin-left: 20px;
  }
  label {
    font-weight: 400;
    color: $dark-gray;
  }
  .description {
    line-height: 1.5;
    color: $dark-gray;
  }
  .form-text {
    margin-bottom: .5em;
  }
  .password-toggle-wrapper {
    display: block;
  }
  .password-toggle {
    margin-top: -1em;
  }
  .confirm-parent,
  .password-parent {
    width: auto;
  }
  .confirm-parent {
    margin-top: 10px;
  }
  div.password-confirm {
    display: none;
  }
  .form-submit {
    display: block;
    width: 100%;
  }
  .notebook-link,
  .button-user-logout {
    &:hover {
      background-color: $dark-red;
      color: $white;
    }
  }
  .form-cancel {
    display: none;
  }
}
.block-paradigm-user-login {
  position: relative;
}
.paradigm-user-block-content {
  .fancybox-inner & {
    padding: 10px 20px;
  }
  a, a:hover {
    color: $red;
  }
  .button {
    color: $white;
    display: block;
    margin-top: 20px;
    text-align: center;
  }
  .login-required-message {
    text-align: center;
    color: $black;
    background-color: $grey-lightest;
    padding: 10px;

    .block-paradigm-user-login & {
      display: none;
    }
  }
}
.paradigm-user-menu-button-text {
  display: none;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: $red;
  text-align: center;
  @include breakpoint($screen-xl) {
    display: inline;
  }
}
.paradigm-user-menu-button {
  position: relative;
  font-size: 18px;
  line-height: 20px;
  padding: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @include breakpoint($screen-xl) {
    width: auto;
    height: auto;
    padding: 10px 24px;
  }
}
.paradigm-user-menu-button-icon {
  width: 24px;
  height: 24px;
}
.paradigm-user-dialog {
  display: none;
  position: fixed;
  left: 50%;
  top: 73px;
  transform: translateX(-50%);
  width: 460px;
  max-width: calc(100vw - 40px);
  background: #fff;
  padding: 15px 20px 20px 20px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  @include breakpoint($screen-s) {
    position: absolute;
    right: 0;
    transform: none;
    left: auto;
  }
  @include breakpoint(500px) {
    max-width: 460px;
  }
  @include breakpoint($screen-m) {
    right: 0;
    left: auto;
    top: 59px;
  }
  max-height: calc(100vh - 75px);
  overflow-x: visible;
  overflow-y: auto;
}
.paradigm-user-dialog-close {
  position: absolute;
  right: 25px;
  top: 20px;
  background: url('../images/btn-close.svg') 0 0 no-repeat;
  background-size: 100% 100%;
  width: 21px;
  height: 21px;
}
.block-paradigm-user-account {
  .paradigm-user-edit-profile-links {
    padding-left: 26px;
    background: url('../images/icon-pencil.svg') 0 4px no-repeat;
    background-size: 20px auto;
    margin-bottom: 1em;
  }
  .paradigm-user-label {
    line-height: 1.5;
    color: $dark-gray;
  }
  .paradigm-user-username {
    margin-bottom: 1em;
  }
}
.item-list .paradigm-user-tabs {
  margin-bottom: 1.5em;
}
.item-list .paradigm-user-tabs li {
  display: inline-block;
  margin: 0;
  margin-right: 15px;
  line-height: 1.5;
}
.paradigm-user-tabs li a,
.paradigm-user-tabs li a:hover {
  display: inline-block;
  font-weight: bold;
  color: #a82c29;
}
.paradigm-user-tabs li.is-active a {
  border-bottom: 2px solid #a82c29;
}
