.paragraphs-item-testimonials {
  padding: 9px;
  background-color: $white-smoke;
  @include breakpoint($screen-xl) {
    padding: 60px;
  }
  .content {
    margin: 52px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 1344px;
  }
  .field-name-field-eyebrow-heading {
    color: $red;
    font-size: 18px;
    line-height: 150%;
  }
  .field-name-field-heading {
    margin: 20px 0px 20px 0px;
    color: $black;
    max-width: 850px;
    font-size: 35px;
    line-height: 1.4;
    font-weight: bold;
    @include breakpoint($screen-l) {
      font-size: 55px;
    }
  }
  .field-name-field-video-testimonials {
    margin-top: 32px;
  }
  .field-name-field-text-testimonials {
    margin-top: 50px;
  }
  .field-name-field-video-testimonials,
  .field-name-field-text-testimonials {
    > .field-items {
      display: flex;
      flex-direction: column;
      text-align: left;
      align-items: stretch;
      justify-content: space-evenly;
      max-width: 1344px;
      .field-item {
        flex: 1;
      }
      @include breakpoint($screen-xl) {
        flex-direction: row;
      }
    }
    .field-name-field-heading {
      margin: 10px 0px 10px 0px;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .node-video-testimonial  {
    margin: 0px 16px;
    .field-name-field-image .field-item,
    .pseudo-video-testimonial-image-link  {
      display: block;
      position: relative;
      cursor: pointer;
      img {
        filter: brightness(0.5);
      }
      :after {
        position: absolute;
        top: 40%;
        left: 40%;
        width: 70px;
        height: auto;
        content: url('../images/play-icon-round.svg');
      }
    }
  }
  .node-teacher-testimonial {
    padding: 32px;
    margin: 16px 16px;
    background-color: $red;
    color: $white;
    max-width: 304px;
    @include breakpoint($screen-xl) {
      margin: 0px 16px;
    }
    p {
      margin: 0px;
    }
    &:before {
      content: url('../images/quote-left-darker-red.svg');
      margin-bottom: -25px;
      text-align: left;
    }
    &:after {
      content: url('../images/quote-right-darker-red.svg');
      margin-top: -50px;
      text-align: right;
    }
    .field-name-body {
      display: flex;
      min-height: 138px;
      text-align: left;
      p {
        max-height: 138px;
        align-self: stretch;
        text-align: left;
        overflow: hidden;
        color: $white;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
      a {
        color: $white;
      }
    }
    .field-name-field-attribution {
      margin-top: 25px;
      margin-bottom: 32px;
      font-size: 18px;
      line-height: 150%;
      font-weight: bold;
      &:before {
        content: '';
      }
    }
  }
  .field-name-field-link {
    padding-top: 48px;
    a {
      @extend %button;
    }
  }
  &.dark-background {
    background-color: $darker-red;
    .field-name-field-eyebrow-heading {
      color: $light-coral;
    }
    .field-name-field-heading {
      color: $white;
    }
    .field-name-field-body {
      color: $white;
    }
  }
}
.fancybox-skin {
  padding: 0px !important;
}
