.art-type-quiz-group {
  position: relative;
  width: auto;
  .art-icon {
    background: url('../images/mc-quiz-icon-complete.svg') 50% 50% no-repeat;
    background-size: contain;
  }
  &.incomplete .art-icon {
    background-image: url('../images/mc-quiz-icon-incomplete.svg');
  }
  &.disabled .art-icon {
    background-image: url('../images/mc-quiz-icon-disabled.svg');
  }
}
.question-header-title {
  text-align: center;
  margin-bottom: 9px;
}
.question-header-subtitle-wrapper {
  position: relative;
  .slider-pager {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.question-header-question-number {
  margin-bottom: 15px;
  text-align: center;
  font-size: 18px;
  font-style: italic;
}
