.paragraphs-item-image-grid {
  display: flex;
  justify-content: center;
  > .content {
    padding: 100px 64px 64px 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    align-self: stretch;
    text-align: center;
  }
  .field-name-field-heading {
    max-width: 850px;
    text-align: center;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    @include breakpoint($screen-xl) {
      font-size: 56px;
    }
  }
  .field-name-field-body {
    max-width: 850px;
    color: $black;
    font-size: 20px;
    letter-spacing: 0.45px;
    line-height: 30px;
  }
  .field-name-field-image-grid-items > .field-items {
    @include breakpoint($screen-xl) {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 16.5fr 16.5fr 16.5fr 16.5fr 16.5fr 16.5fr;
      grid-template-rows: 49fr 49fr;
      max-width: 1312px;
      max-height: 960px;
    }
    > :nth-child(1) {
      @include breakpoint($screen-xl) {
        grid-column: 1 / 3;
        grid-row: 1;
        overflow: hidden;
      }
    }
    > :nth-child(2) {
      @include breakpoint($screen-xl) {
        grid-column: 3 / 5;
        grid-row: 1;
        overflow: hidden;
      }
    }
    > :nth-child(3) {
      @include breakpoint($screen-xl) {
        grid-column: 5 / 7;
        grid-row: 1;
        overflow: hidden;
      }
    }
    > :nth-child(4) {
      @include breakpoint($screen-xl) {
        grid-column: 1 / 3;
        grid-row: 2;
        overflow: hidden;
      }
    }
    > :nth-child(5) {
      @include breakpoint($screen-xl) {
        grid-column: 3 / 5;
        grid-row: 2;
        overflow: hidden;
      }
    }
    > :nth-child(6) {
      @include breakpoint($screen-xl) {
        grid-column: 5 / 7;
        grid-row: 2;
        overflow: hidden;
      }
    }
    .group-text-group {
      position: absolute;
      left: 0px;
      bottom: 0px;
      text-align: start;
      padding: 25px;
      @include breakpoint($screen-m) {
        padding: 48px;
      }
    }
    img {
      width: 100%;
      max-width: 100%;
    }
    > .field-item {
      position: relative;
      .field-name-field-character {
        font-size: 18px;
        color: $white;
        font-weight: bold;
        line-height: 120%;
        @include breakpoint($screen-m) {
          font-size: 32px;
        }
      }
      .field-name-field-play a {
        color: $white;
        text-decoration: underline;
      }
    }
  }
  &.dark-background {
    background-color: $white-smoke;
  }
}
