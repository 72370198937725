.view-teacher-testimonials {
  &.view-display-id-block {
    max-width: 900px;
    margin: 40px auto;
    padding: 0 10px;
    @include breakpoint($screen-l) {
      margin: 120px auto;
    }
  }

  h2 {
    margin-bottom: 20px;
    color: $red;
    font-size: 48px;
    line-height: 75px;
    text-align: center;
    .caps {
      font-size: 54px;
    }
  }
  .more-link {
    margin: 60px auto 80px;
    text-align: center;
    a {
      @extend %button;
      color: $white;
    }
  }
  .views-row {
    clear: both;
  }
}
