.node-type-blog-post {
  .page-wrapper {
    max-width: 990px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 50px;
  }

  .page-title-wrapper {
    display: none;
  }

  .field-name-authored-date,
  .field-name-field-blog-author {
    margin-top: 15px;
    color: $grey-neutral;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.233px;
    text-align: right;
  }
  
  .field-name-authored-date {
    padding-top: 15px;
  }

  .field-name-field-blog-author {
    margin-bottom: -50px;
  }

  .field-name-teaser-title {
    margin: 30px;
    color: $red;
    font-size: 43px;
    letter-spacing: -0.418px;
    line-height: 1.2;
    text-align: center;
    .caps {
      &:first-child {
        font-size: 76px;
        letter-spacing: -0.739px;
      }
      &:not(:first-child) {
        font-family: $baskerville;
      }
    }
  }

  .field-name-field-blog-category {
    display: flex;
    margin-top: 85px;
    justify-content: center;
    border-top: 2px solid $red;
    .field-items {
      margin-top: -20px;
    }
    .field-item {
      position: relative;
      display: inline-block;
      margin-bottom: 60px;
      padding: 4px 24px;
      color: $white;
      background-color: $red;
      font-size: 12px;
      letter-spacing: 0.22px;
      line-height: 1.5;
      &:after, &:before {
        content: '';
        position: absolute;
        height: 0;
        width: 0;
        top: 0;
        border-style: solid;
        border-width: 0;
      }
      &:before {
        border-color: $red transparent;
        border-width: 13px 0 13px 8px;
        left: -8px;
      }
      &:after {
        border-color: $red transparent;
        border-width: 13px 8px 13px 8px;
        right: -8px;
      }
    }
  }
  ol {
    list-style-type: decimal;
  }
  ul {
    list-style-type: disc;
  }
  .blog-section-header {
    color: $red;
    font-size: 24px;
  }
}
