$notebook-export-line-height: 24px;

.notebook-export {
  #admin-bar,
  .messages {
    display: none;
  }
}

.admin-bar .notebook-export {
  border-top: none !important;
}

#paradigm-notebook-export {
  .myshakespeare-logo-text {
    font-size: 32px;
    color: $black;
  }
}
.notebook-export-header {
  display: flex;
  .page-title {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 30px;
    color: $red;
    font-size: 48px;
    .caps {
      font-size: 84px;
    }
    @include breakpoint($screen-m) {
      padding-left: 0;
    }
  }
  .notebook-export-metadata {
    margin-left: auto;
    text-align: right;
  }
}
.notebook-export-heading {
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
}
.notebook-export-filters-wrapper {
  margin-bottom: 40px;
}
.notebook-export-filters,
.notebook-export-entries {
  th,
  td {
    vertical-align: top;
  }
  td,
  th,
  thead tr:hover > th,
  thead tr:hover > td,
  .notebook-entry:hover td {
    background-color: $grey-lightest;
  }
}
.notebook-export-filters-table-wrapper {
  border-top: 2px solid $grey-light;
  background-color: $grey-lightest;
  padding-bottom: 10px;
}
.notebook-export-filters {
  margin-top: 0;
  margin-bottom: 0;
  tbody,
  thead {
    tr:hover {
      > td,
      > th {
        background-color: $grey-lightest;
      }
    }
  }
  th {
    font-size: 14px;
    line-height: 16px;
    padding: 20px 14px;
    border-top: none;
  }
  tbody tr:first-child td {
    border-top: none;
  }
  td {
    font-size: 12px;
    line-height: 18px;
    background-color: $grey-lightest;
    border-top: none;
  }
  .group-checkboxes-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .field-group--task-type {
    margin-right: 20px;
  }
  .field-group--task-type,
  .field-group--annotation-type {
    display: flex;
    line-height: 27px;
    width: 100%;
    @include breakpoint($screen-l) {
      width: auto;
    }
    > label {
      width: 125px;
      @include breakpoint($screen-l) {
        width: auto;
      }
    }
  }
  .field--checkbox {
    @extend %notebook-checkbox;
  }
  .field-group--annotation-type {
    .field {
      @include notebook-annotation-colors-field('field--checkbox-annotation-type');
      label {
        padding-left: 30px;
        color: $black;
      }
    }
  }
}

@mixin notebook-export-box-with-border {
  padding: 5px;
  background: $white;
  border: 1px solid $grey-light;
  border-radius: 3px;
}

.notebook-export-entries {
  th,
  h5,
  .notebook-entry--written-quiz-title {
    font-size: 12px;
    line-height: 24px;
    font-weight: bold;
  }
  table {
    margin-top: 0;
    th {
      border-top: none;
    }
  }
  > thead > tr > th,
  > thead > tr:hover > th {
    background: white;
    border-bottom: 1px solid $black;
    font-size: 14px;
  }
  tbody td button {
    width: auto;
  }
  tbody tr:first-child td {
    border-top: none;
  }
  .notebook-entry > td {
    border-top: 1px solid $black;
  }
  .notebook-entry > td {
    padding: 0;
  }
  .notebook-entry--play-cell {
    color: $red;
    width: 26%;
  }
  .notebook-entry--task-cell {
    width: 20%;
  }
  .notebook-entry--description-cell {
    width: 40%;
  }
  .notebook-entry--status-cell {
    width: 14%;
  }
  .notebook-entry--body {
    td {
      font-size: 10px;
      line-height: $notebook-export-line-height;
    }
  }
  /* Lines */
  .lines-renderer-wrapper {
    padding: 10px;
    .character-name,
    .line {
      padding-left: 24px;
      padding-right: 0;
      line-height: $notebook-export-line-height;
      @include breakpoint($screen-m) {
        padding-left: 24px;
        padding-right: 0;
      }
      @include breakpoint($screen-l) {
        padding-left: 24px;
        padding-right: 0;
      }
    }
    .character-name {
      font-size: 12px;
    }
    .line {
      font-size: 10px;
    }
    .line-number {
      line-height: $notebook-export-line-height;
    }
    .line-text {
      line-height: $notebook-export-line-height;
      @include breakpoint($screen-l) {
        line-height: $notebook-export-line-height;
      }
    }
    .gloss-def {
      font-size: 9px;
      top: 17px;
    }
    .line-number,
    .line-number--10th {
      left: 0;
      right: auto;
      @include breakpoint($screen-m) {
        left: 0;
        right: auto;
      }
      @include breakpoint($screen-l) {
        left: 0;
        right: auto;
      }
    }
  }
  .notebook-entry--field {
    margin-bottom: 16px;
  }
  .notebook-entry--field-tags {
    @include notebook-export-box-with-border;
    line-height: 14px;
    min-height: 25px;
  }
  /* Annotation Export Entry */
  .notebook-entry--annotation-notes {
    @include notebook-export-box-with-border;
    min-height: 25px;
  }
  .color {
    display: block;
    padding-left: 30px;
    position: relative;
    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 22px;
      height: 22px;
      border: 2px solid $grey-dark;
    }
  }
  .color-yellow {
    &:before {
      background-color: $yellow;
    }
  }
  .color-pink {
    &:before {
      background-color: $pink;
    }
  }
  .color-green {
    &:before {
      background-color: $green-medium;
    }
  }
  .color-blue {
    &:before {
      background-color: $blue-light;
    }
  }
  .notebook-entry--multiple-choice-quiz-title {
    color: $red;
  }
  /*Multiple Choice Questions*/
  .notebook-entry--multiple-choice-quiz-question,
  .notebook-entry--written-quiz-question {
    line-height: 16px;
    font-size: 12px;
    .question-indicator {
      font-size: 10px;
      text-align: center;
    }
  }
  .multiple-choice-option {
    padding: 10px;
    &.correct {
      border: 1px solid $green;
    }
    &.incorrect {
      border: 1px solid $red;
    }
  }
  .multiple-choice-option-label-enumerator,
  .multiple-choice-option-label {
    line-height: 16px;
  }
  .multiple-choice-option-description {
    p {
      font-size: 10px;
      line-height: 14px;
    }
  }
  .notebook-entry--multiple-choice-quiz-no-answer {
    @include notebook-export-box-with-border;
  }
  /*Written Quiz*/
  .notebook-entry--written-quiz-answer {
    @include notebook-export-box-with-border;
  }
}
