@mixin active-tab() {
  gap: 16px;
  border-bottom: 2px solid $red;
  background: $white;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
  .field-name-field-feature-icon {
    filter: grayscale(0%);
    opacity: 1;
  }
  .field-name-field-heading {
    color: $red;
  }
  .field-name-field-feature-description {
    color: $black;
  }
}