.node-full-scene-modern-english {

  .field-name-field-play-term,
  .group-act-and-scene {
    text-align: center;
  }

  .field-name-field-play-term:after {
    content: 'Full Scene Modern English';
  }

  .group-act-and-scene {
    color: $medium-gray;
    font-size: 1.5em;
    line-height: 0.75 * $base-line-height;
  }

  .field-name-field-quick-study {
    background-image: url(../images/icon-chat-bubble.png);
    margin: 0px auto 75px;

    @media all and (max-width: $screen-m) {
      &:before {
        content: '';
        display: block;
        float: left;
        padding: 33px 40px;
      }
    }
  }

  .field-name-body,
  .field-name-field-index-reference-notes {
    margin-bottom: 40px;
  }

  .field-name-body {
    h3 {
      color: $action-color;
      padding: 0px;
      margin-bottom: 0.5 * $small-spacing;
      font-size: rem(20px);
    }
    p {
      line-height: 2em;
    }
  }
}
