.character-name,
.character-name--top-margin {
  @extend %h3;
  font-weight: bold;
  display: inline-block;

  @media all and (max-width: $screen-m) {
    margin-top: 20px;
    font-size: rem(16);
  }

  @media all and (max-width: $screen-l) {
    margin-top: 15px;
  }
}
.character-name--top-margin {
  margin-top: 1em;
}
.stage-direction {
  position: relative;
  font-style: italic;
  line-height: rem(40);
  padding: 0px 55px;

  @include breakpoint($screen-m) {
    padding: 0px 140px 0 100px;
  }

  @include breakpoint($screen-l) {
    line-height: rem(34);
    padding: 0px 290px 0px 160px;
  }

  &:first-child {
    line-height: rem(34);
    text-align: center;
    padding: 0 20px;

    @include breakpoint($screen-m) {
      padding: 0px 140px 0 100px;
    }

    @include breakpoint($screen-l) {
      text-align: left;
      padding: 0px 290px 0px 160px;
    }
  }
}
.paragraphs-items-field-paragraphs {
  position: relative;
}
h3.character-name,
.character-name--top-margin,
.field-name-field-character-name,
.line,
.paragraphs-item-video-link .group-banner,
.paradigm-rc-take-quiz-header {
  padding-left: 50px;
  padding-right: 65px;
  position: relative;

  @include breakpoint($screen-m) {
    padding-left: 100px;
    padding-right: 100px;
  }

  @include breakpoint($screen-l) {
    padding-left: 120px;
    padding-right: 230px;
  }
}
.paragraph-line-type-stage-direction > .content {
  padding-left: 180px;
  font-style: italic;
}
.line-number,
.line-number--10th {
  position: absolute;
  top: 0;
  left: 10px;
  text-align: right;
  color: #ccc;
  line-height: rem(40);

  @include breakpoint($screen-m) {
    left: 50px;
  }

  @include breakpoint($screen-l) {
    right: 230px;
    left: auto;
    line-height: rem(34);
  }
}
.line-number--10th{
  color: $black
}

[data-nocopy]::before{
  content: attr(data-nocopy);
}
.gloss-missing-word,
.gloss-missing-word-eol {
  [data-nocopy]::before{
    content: "(" attr(data-nocopy) ")";
  }
}

.line-text {
  position: relative;
  line-height: rem(40);

  @include breakpoint($screen-l) {
    line-height: rem(32);
  }
}

.word,
.space {
  position: relative;
}

.word.is-highlighted-bg,
.space.is-highlighted-bg,
.is-highlighted-bg .line-text,
.word.is-annotation-bg,
.space.is-annotation-bg,
.is-annotation-bg .line-text {
  background-color: rgba(255, 238, 150, 0.88);
  font-weight: bold;
}

.line-anchor {
  position: absolute;
  top: -150px;
}

.line-scrubber-wrapper {
  font-size: 2em;
  line-height: 1;
  color: $red;
  position: absolute;
  top: 0;
  left: 20px;
  width: 32px;

  @include breakpoint($screen-l) {
    left: 90px;
  }
}
.line-scrubber {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  cursor: all-scroll;
  width: 32px;
  line-height: rem(40);

  @include breakpoint($screen-l) {
    line-height: rem(34);
  }
}
.line-snap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1em;
  z-index: 1;
}
.line-highlighter {
  position: absolute;
  border-left: 4px solid $black;
  width: 0;
  height: 0;
  top: 0;
  left: 85px;
}
.word.is-highlighted-bar {
  text-decoration: underline;
}
