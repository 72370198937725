.footer {
  position: relative;
  z-index: 500;
}
.footer-top {
  position: relative;
  padding-top: 10px;
  background-color: $white-smoke;

  @include breakpoint($screen-m) {
    padding-top: 0;
  }

  .row-fluid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    padding: 50px 0px 0px 0px;

    @include breakpoint($screen-xl) {
      padding: 80px 64px 0px 64px;
      flex-direction: row;
      align-items: baseline;
    }
    .span3 {

      @include breakpoint($screen-m) {
        margin: 0 auto;
      }

      @include breakpoint($screen-xl) {
        width: auto;
        margin: 0;
      }

      .block-paradigm-blocks-logo-block,
      .block-menu-menu-footer {
        margin-bottom: 25px;
        @include breakpoint($screen-l) {
          margin-bottom: 70px;
        }
      }
      .block-menu-menu-footer {
        margin-top: 15px;
      }
    }
  }

  .block-paradigm-blocks-myshakespeare-logo-text {
    display: block;
    line-height: normal;
    font-weight: bold;
    .caps {
      font-weight: normal;
    }
  }

  .tiktok {
    img  {
      max-width: 20px;
    }
  }
}

.footer-top-center {
  display: flex;
  flex-direction: column;
  .block-menu-menu-footer .menu {
    flex-direction: row;
    align-items: center;
  }
}
.footer-middle {
  padding: 0px 0px 50px 0px;
  display: flex;
  flex-direction: column;
  background-color: $white-smoke;
  border-bottom: 1px solid $grey-dark;

  @include breakpoint($screen-xxl) {
    padding: 0px 64px 80px 64px;
  }
  .block-views-play-list-footer {
    .view-content {
      max-width: 340px;
      @include breakpoint($screen-xl) {
        max-width: 985px;
      }
    }
  }
  .view-play-list {
    padding-left: 18px;
    padding-right: 20px;
    @include breakpoint($screen-m) {
      padding-right: 0;
    }
    @include breakpoint($screen-xl) {
      padding-left: 0;
    }
    .views-row {
      width: 50%;
      @include breakpoint($screen-xl) {
        width: 30%;
      }
    }
  }

  .field-name-field-play-logo {
    margin: 0 15px;
    max-width: 128px;

    @include breakpoint($screen-m) {
      width: 128px;
    }
  }
  .logo-midsummer-nights-dream .field-name-field-play-logo,
  .logo-Midsummer-Night039s-Dream .field-name-field-play-logo {
    // Midsummer needs to be 1.28 times the size of the other logos
    max-width: 164px;

    @include breakpoint($screen-m) {
      width: 164px;
    }
  }
}
.footer-bottom {
  padding-top: 32px;
  padding-bottom: 80px;
  background-color: $white-smoke;
  color: $black;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  line-height: 1.875rem;

  span {
    width: 100%;
    text-align: center;
    padding: 5px;

    @include breakpoint($screen-m) {
      width: auto;
      text-align: left;
      padding: 0;
    }

    &.copyright {
      font-size: 14px;
      line-height: 150%;
    }
  }

  a {
    color: $black;
    text-decoration: underline;
    font-size: 14px;
    line-height: 150%;
  }

  li {
    max-width: 200px;
    margin-left: 25px;
  }

  .legal {
    .content {
      .menu-tree {
        display: flex;
        flex-direction: row;
      }
      li.leaf {
        list-style-type: none;
        list-style-image: none;
        padding: 0;
        margin-left: 25px;
      }
    }
  }
}
