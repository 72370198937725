.page-notebook-export {
  #admin-menu,
  #sticky-nav,
  #main-menu,
  .page > .footer,
  .slicknav_menu {
    display: none;
  }
  &.admin-menu .page-wrapper {
    padding-top: 0;
  }
}
