/**
 * Override the breakpoint mixin provided in the neato theme.
 * The other breakpoint mixin was using '@media screen', thereby
 * breaking styling in the notebook export. I've now hard-coded it
 * to 'all' since we don't have other print styles.
 */
@mixin breakpoint($query:$feature $value $columns, $total-columns: $grid-columns) {
  @if length($query) == 1 {
    @media all and ($default-feature: nth($query, 1)) {
      $default-grid-columns: $grid-columns;
      $grid-columns: $total-columns;
      @content;
      $grid-columns: $default-grid-columns;
    }
  } @else if length($query) == 2 {
    @media all and (nth($query, 1): nth($query, 2)) {
      $default-grid-columns: $grid-columns;
      $grid-columns: $total-columns;
      @content;
      $grid-columns: $default-grid-columns;
    }
  } @else if length($query) == 3 {
    @media all and (nth($query, 1): nth($query, 2)) {
      $default-grid-columns: $grid-columns;
      $grid-columns: nth($query, 3);
      @content;
      $grid-columns: $default-grid-columns;
    }
  } @else if length($query) == 4 {
    @media all and (nth($query, 1): nth($query, 2)) and (nth($query, 3): nth($query, 4)) {
      $default-grid-columns: $grid-columns;
      $grid-columns: $total-columns;
      @content;
      $grid-columns: $default-grid-columns;
    }
  } @else if length($query) == 5 {
    @media all and (nth($query, 1): nth($query, 2)) and (nth($query, 3): nth($query, 4)) {
      $default-grid-columns: $grid-columns;
      $grid-columns: nth($query, 5);
      @content;
      $grid-columns: $default-grid-columns;
    }
  }
}
@mixin logo-text($small-text-size, $large-text-size) {
  color: $black;
  font-size: $small-text-size;

  @include breakpoint($screen-s) {
    font-size: $large-text-size;
  }

  .red {
    color: $red;
  }
  .caps {
    font-family: $ivory-headline;
    font-size: $small-text-size * 1.4;

    @include breakpoint($screen-s) {
      font-size: $large-text-size * 1.4;
    }
  }
}
@mixin responsive-video() {
  width: 100%;
  height: 0;
  padding-bottom: 56.3%;
  position: relative;
  iframe {
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
  }
}
@mixin toggle-icon() {
  display: inline-block;
  width: 45px;
  height: 23px;
  line-height: 23px;
  border: 1px solid $red;
  border-radius: 15px;
  position: relative;
  margin-right: 5px;
  background: $red url('../images/toggle-checkmark.svg') 4px 50% no-repeat;
  transition: all 2s;
  &.is-off {
    background-color: $white;
  }
  &:before {
    width: 19px;
    height: 19px;
    content: '';
    position: absolute;
    background: url('../images/circle-red-19x19.svg') 50% 50% no-repeat;
    top: 1px;
  }
  &.is-on:before {
    right: 2px;
    filter: brightness(0) invert(1);
  }
  &.is-off:before {
    left: 2px;
  }
  &.is-off:after {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    top: 5px;
    right: 5px;
    border: 2px solid $grey-dark;
    border-radius: 100%;
  }
}
.toggle-icon {
  @include toggle-icon;
}
@mixin line-section() {
  padding-left: 50px;
  padding-right: 65px;
  position: relative;

  @include breakpoint($screen-m) {
    padding-left: 100px;
    padding-right: 100px;
  }

  @include breakpoint($screen-l) {
    padding-left: 120px;
    padding-right: 230px;
  }
}
