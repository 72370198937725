.field-name-field-headline,
.field-name-field-headline-text,
.pseudo-video-banner-headline-link,
.pseudo-video-link-headline-link {
  text-align: center;
  font-size: rem(24px);
  line-height: rem(35px);
  margin-bottom: 15px;
  color: $red;
}

.field-name-field-headline-subtitle {
  font-size: rem(18px);
  line-height: rem(18px);
  text-align: center;
}

.field-name-field-characters {
  .info {
    color: $black;
    position: relative;
    &:hover {
      cursor: pointer;
    }
    span {
      display: none;
    }
  }
}

.field-name-field-act,
.field-name-field-scene {
  margin-left: 2px;
  margin-right: 2px;
}
