.paragraphs-item-blog-two-column-text-image .content {
  display: flex;
  flex-direction: column;
  @include breakpoint($screen-m) {
    flex-direction: row;
  }

  .group-left-column {
    @include breakpoint($screen-m) {
      width: 50%;
      padding-right: 15px;
    }
  }

  .group-right-column {
    margin-bottom: 30px;
    @include breakpoint($screen-m) {
      width: 50%;
      padding-left: 15px;
    }
  }

  .field-name-field-content {
    margin-bottom: 30px;
    color: $grey-neutral;
    font-size: 18px;
    line-height: 2;
    letter-spacing: 0.45px;
  }

  .field-name-field-image img {
    display: block;
  }

  .field-name-field-caption {
    margin-top: 10px;
    margin-left: 20%;
    padding-left: 15px;
    border-left: 2px solid $red;
    color: $grey-neutral;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.233px;
    @include breakpoint($screen-m) {
      margin-left: 0px;
    }
  }
}