.react-autosuggest__container {
  position: relative;

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .react-autosuggest__suggestions-container {
    display: none;
  }
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 44px;
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    border: 1px solid $grey-medium;
    background-color: $white;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 1000;
  }
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
    border-bottom: solid 1px $grey-lighter;
  }
  .react-autosuggest__suggestion--highlighted {
    background-color: $grey-lighter;
  }
  .react-autosuggest__input {
    height: 46px;

    &:disabled {
      -webkit-text-fill-color: $black;
      opacity: 1;
    }
  }
  .input-container {
    position: relative;
  }
  .clear-input {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 21px;
    background-color: $grey-lighter;
    padding: 2px 13px;
    border-radius: 0px 3px 3px 0px;
    height: 46px;
  }
  .loading-spinner {
    display: block;
    position: absolute;
    top: 3px;
    right: 50px;
    background: transparent url('../images/loading-simple.gif') center center no-repeat;
    height: 40px;
    width: 40px;
    background-size: contain;
  }
}
