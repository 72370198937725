.react-toggle {
  display: block;
  line-height: 20px;
  padding: 15px 60px 14px 9px;
  position: relative;
  cursor: pointer;
  color: $red;
}
.react-toggle-indicator {
  display: block;
  position: absolute;
  top: 14px;
  right: 0;
  width: 55px;
  height: 22px;
  border-radius: 18px;
  cursor: pointer;
  background-color: $red;
  transition: .4s;
  &:before {
    position: absolute;
    content: "";
    top: 2px;
    left: 35px;
    height: 18px;
    width: 18px;
    border-radius: 18px;
    background-color: $white;
    transition: .4s;
  }
  &:after {
    content: 'ON';
    position: absolute;
    right: 28px;
    top: 5px;
    color: $red;
    font: 12px Arial;
    @include breakpoint($screen-m) {
      color: $white;
    }
  }
}
.block-paradigm-blocks-gloss-toggle {
  padding-right: 9px;
  display: none;
  @include breakpoint($screen-m) {
    display: block;
  }
}
.is-toggled > .react-toggle-indicator {
  background-color: $grey-accessible;
  &:before {
    left: 2px;
  }
  &:after {
    content: 'OFF';
    position: absolute;
    left: 23px;
    top: 5px;
    color: $red;
    font: 12px Arial;
    @include breakpoint($screen-m) {
      color: $white;
    }
  }
}