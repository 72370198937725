.tooltip-wrapper {
  box-shadow: 0px 0px 14px rgba(0,0,0,0.3);
  border-radius: 5px;
  max-width: 400px;
  background: $white;
  box-sizing: border-box;
  transition: transform .5s;
  position: absolute;
  top: 0;
  left: -100px;
  z-index: 1;
  .scroll-y {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100%;
  }
}
.tooltip-teaser {
  width: auto;
  /* white-space:
   * this is a hack so the heading
   * text white space won't break
   * and we can determing the auto
   * width of the line
   */
  white-space: nowrap;
}
.tooltip-full {
  width: 400px;
}
.tooltip-teaser-content {
  @include artool-text;
  padding: 10px;

  h3 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 7px;
  }
  h3.character-name {
    padding: 0;
  }
  p {
    margin-bottom: 7px;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
.tooltip-arrow-left,
.tooltip-arrow-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  z-index: 10;
}
.tooltip-arrow-right {
  right: -6px;
  border-left: 6px solid $white;
}
.tooltip-arrow-left {
  left: -6px;
  border-right: 6px solid $white;
}
