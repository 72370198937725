.paragraphs-item-site-landing-page-snapshot-tabs {
  padding: 14px;
  background-color: $white;
  @include breakpoint($screen-xl) {
    padding: 112px 40px;
  }
  .field-name-field-tab-section-intro {
    width: 85%;
    margin: 0px auto 20px;
    font-size: 20px;
    color: $black;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    @include breakpoint($screen-m) {
      margin: 0px auto 40px;
      font-size: 36px;
    }
    @include breakpoint($screen-l) {
      width: 890px;
      margin: 0px auto 80px;
      font-size: 56px;
    }
  }
  .tab-sections {
    position: relative;
    overflow: auto;
    max-width: 1312px;
    margin: 0 auto;
    @include breakpoint($screen-xl) {
      padding-bottom: 50px;
    }
  }
  .tab-section-title {
    margin-bottom: 16px;
    font-size: 18px;
    color: $black;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    max-width: 653px;
    @include breakpoint($screen-l) {
      font-size: 40px;
    }
  }
  .tab-section-subtitle {
    padding-bottom: 40px;
    color: $black;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
  .slideshow-tab {
    display: flex;
    padding: 24px;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    height: auto;
    @include breakpoint($screen-l) {
      max-width: 653px;
      height: 130px;
    }
    &:hover {
      @include breakpoint($screen-xl) {
        @include active-tab();
      }
    }
    &.is-active {
      @include breakpoint($screen-xl) {
        @include active-tab();
      }
    }
  }
  .field-name-field-feature-icon {
    margin-right: 0px;
    filter: grayscale(100%);
    opacity: 0.5;
    img {
      display: block;
      margin: 0px;
      width: 42px;
      min-width: 42px;
    }
  }
  .group-landing-page-snapshot-tabs {
    display: flex;
    flex-direction: row;
  }
  .field-name-field-heading {
    font-size: 20px;
    font-weight: bold;
    line-height: 130%;
  }
  .field-name-field-feature-description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  .slideshow-panel {
    position: absolute;
    top: 50px;
    left: 77%;
    margin: 0;
    display: none;
    width: 30%;
    transform: translateX(-50%);
    @include breakpoint($screen-xl) {
      width: 580px;
    }
    img {
      box-shadow: -1px 3px 11px -3px rgba(0,0,0,0.5);
      flex-shrink: 0;
      border-radius: 16px;
    }
    &.is-active {
      @include breakpoint($screen-xl) {
        display: inline;
      }
    }
  }
  &.image-left-align {
    .field-collection-item-field-snapshot-tabs {
      > .content {
        display: flex;
        @include breakpoint($screen-xl) {
          justify-content: end;
        }
      }
      .slideshow-panel {
        left: 20%;
        @include breakpoint($screen-xl) {
          left: 25%;
        }
      }
    }
    .field-collection-item-field-tab-section {
      > .content {
        display: flex;
        flex-direction: column;
        .field-name-field-tab-section-title,
        .field-name-field-tab-section-subtitle,
        .group-landing-page-snapshot-tabs {
          width: 80%;
          align-self: flex-start;
          @include breakpoint($screen-xl) {
            width: 616px;
            align-self: end;
          }
        }
      }
    }
  }
  &.dark-background {
    background: $white-smoke;
  }
}
